<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Envío de {{ $store.state.seleccion === 'SMS' ? 'SMS' : 'Whatsapp'}} </h5>
      <p class="mb-0 text-sm">Fecha de Envío</p>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="row mt-3 ms-1">
        <div class="col-12 col-sm-6 form-check">
          <input
              id="ahora"
              v-model="seleccionProgramacion"
              type="radio"
              name="programacion"
              value="ahora"
              class="form-check-input"
          />
          <label for="ahora">Ahora</label>
        </div>
        <div class="col-12 col-sm-6 form-check">
          <input
              id="programar"
              v-model="seleccionProgramacion"
              type="radio"
              name="programacion"
              value="programar"
              class="form-check-input"
          />
          <label for="programar">Programar</label>
          <div :style="{ 'pointer-events': (seleccionProgramacion === 'programar') ? 'auto' : 'none' }">
            <argon-input
                id="datetime-local"
                v-model="fecha"
                type="datetime-local"
                class="multisteps-form__input"
                :min="minDateTime"
                placeholder="Selecciona una fecha"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer border-1 mt-4 d-flex">
      <argon-button
        type="button"
        color="light"
        variant="gradient"
        class="js-btn-prev"
        @click="$parent.prevStep"
      ><i class="fas fa-chevron-left me-2"></i> Anterior
      </argon-button>
      <argon-button
        type="button"
        color="celcom"
        variant="gradient"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="nextStepValor"
      >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { formatoFecha, convertirHoraChileATimestampUTC } from "@/config/config";
import Swal from "sweetalert2";
import {getTimeServe} from "@/config/servicios/campana/util";

export default {
  name: "ProgramadaEditAdd3",
  components: {
    ArgonInput,
    ArgonButton,
  },
  props: {
    sendingEditar: {
      type: Object,
      default: () => ({}),
    },
    editando: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateValues3"],
  data() {
    return {
      fecha: "",
      minDateTime: "",
      seleccionProgramacion: "ahora",
      rut: "",
      selectedSource: "",
      selectedTag: "",
      tags: "",
    };
  },
  watch: {
    sendingEditar: {
      handler(newValidForm) {
        if (newValidForm?.calendar) {
          this.fecha = this.fecha_Formato(newValidForm.calendar);
        }
      },
      immediate: true,
      deep: true,
    },
    seleccionProgramacion(newVal) {
      if (newVal === "programar") {
        this.fecha = this.minDateTime;
      }else{
        this.fecha = "";
      }
    },
  },
  mounted() {
    this.updateMinDateTime();
    setInterval(this.updateMinDateTime, 60000); // Actualiza cada minuto
  },
  methods: {
    async updateMinDateTime() {
      try {
        // Obtener la hora del servidor en formato HH:mm:ss
        let serverTime = await getTimeServe();
        
        // Validar que la respuesta tenga el formato correcto
        if (!serverTime || !/^\d{2}:\d{2}:\d{2}$/.test(serverTime)) {
          throw new Error("Formato de hora del servidor no válido: " + serverTime);
        }

        // Obtener la fecha del servidor en UTC (sin ajustes locales)
        const serverDateUTC = new Date(); // Creamos un objeto Date
        const [hours, minutes, seconds] = serverTime.split(":").map(Number);
        
        // Reemplazar la hora local con la hora del servidor
        serverDateUTC.setUTCHours(hours, minutes, seconds, 0);

        // Sumar 15 minutos a la hora del servidor
        serverDateUTC.setUTCMinutes(serverDateUTC.getUTCMinutes() + 15);

        // Convertir al formato correcto para datetime-local (YYYY-MM-DDTHH:mm)
        this.minDateTime = serverDateUTC.toISOString().slice(0, 16);

        console.log("Hora del servidor:", serverTime);
        console.log("minDateTime actualizado:", this.minDateTime);
        
      } catch (error) {
        console.error("Error actualizando minDateTime:", error.message);
      }
    },
    fecha_Formato(fecha) {
      return formatoFecha(fecha);
    },
    async nextStepValor() {
      try {
        // Obtener la hora actual del servidor en formato HH:mm:ss
        let serverTime = await getTimeServe();

        // Validar que la respuesta tenga el formato correcto
        if (!serverTime || !/^\d{2}:\d{2}:\d{2}$/.test(serverTime)) {
          throw new Error("Formato de hora del servidor no válido: " + serverTime);
        }

        // Obtener la fecha de hoy en formato YYYY-MM-DD
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Mes en 2 dígitos
        const day = String(today.getDate()).padStart(2, '0');

        // Extraer la hora, minutos y segundos del servidor
        const [hours, minutes, seconds] = serverTime.split(":").map(Number);

        // Crear un objeto Date con la fecha actual pero con la hora del servidor
        let serverDate = new Date(year, month - 1, day, hours, minutes, seconds);

        // Obtener el timestamp de la hora del servidor en milisegundos
        const now = serverDate.getTime();

        // Obtener la fecha seleccionada en milisegundos
        const selectedDate = new Date(this.fecha).getTime();

        // Calcular la diferencia en minutos
        const diferenciaMinutos = (selectedDate - now) / (1000 * 60);

        if(this.seleccionProgramacion === "ahora"){

          this.fecha = convertirHoraChileATimestampUTC(serverDate);
        }else{     
          // Validar si la fecha seleccionada es válida
          if (diferenciaMinutos < 15) {
            Swal.fire({
              icon: "warning",
              title: "Fecha no válida",
              text: "La fecha seleccionada debe ser al menos 15 minutos después de la hora actual del servidor.",
              confirmButtonText: "Entendido",
              confirmButtonColor: "#3085d6",
            });
            return;
          } 
          this.fecha = convertirHoraChileATimestampUTC(this.fecha); 
        }

        this.$emit("updateValues3", { fecha: this.fecha });

        // Enviar según la selección
        if (this.$store.state.seleccion === "SMS") {
          await this.$parent.envioSms();
        } else if (this.$store.state.seleccion === "Whatsapp") {
          await this.$parent.envioWhatsapp();
        }

      } catch (error) {
        console.error("Error en nextStepValor:", error.message);
      }
    },
  },
};
</script>
