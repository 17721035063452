<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Envío de Correo</h5>
      <p class="mb-0 text-sm">Mensaje</p>
    </div>
    <div class="card-body multisteps-form__content">
      <div class="col-12 col-sm-6">
        <div class="row ms-1">
          <div class="form-check col-md-6">
            <input
                id="redactar"
                v-model="switchValueMen"
                type="radio"
                name="redactar"
                value="redactar"
                class="form-check-input"
                @change="limpiar"
            />
            <label for="redactar">Redactar</label>
          </div>
          <div class="form-check col-md-6">
            <input
                id="plantilla"
                v-model="switchValueMen"
                type="radio"
                name="plantilla"
                value="plantilla"
                class="form-check-input"
                @change="limpiar"
            />
            <label for="plantilla">Plantillas</label>
          </div>
        </div>
      </div>
      <hr>
      <div v-if="switchValueMen === 'redactar'">
      <div class="row">
          <div class="col-12 col-sm-6">
            <label>Asunto
              <span
                  v-if="contHeader === 0"
                  type="button"
                  class="badge badge-celcom-orange"
                  title="Haz clic para agregar una variable"
                  @click="addVariable">
                  Agregar nuevo <i class="fas fa-plus fa-xl"></i>
              </span>
            </label>
            <argon-input
                id="textoCabeceraEmail"
                v-model="textoCabecera"
                class="multisteps-form__input m-0"
                type="text"
                placeholder="Asunto"
            />
            <div class="d-flex align-items-center w-100 mb-2">
              <p v-if="!validarAsunto" class="text-danger m-0 flex-grow-1">
                El asunto es obligatorio.
              </p>
              <div class="text-end mb-2 flex-grow-1">
              <Emoji @emoji-selected="handleEmojiSelected"/>
            </div>
            </div>
          </div>
          <div v-if="asunto" class="col-12 col-sm-6">
            <label>Elija Variable</label>
            <select
                id="selectCab2"
                v-model="selectedCabsubject"
                class="multisteps-form__select form-control form-select"
                @change="putFieldSubject"
            >
              <option selected="selected" disabled value="">Variable</option>
              <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                      :key="cabIndex"
                      :value="cab"
              >{{ traducirHeader(cab.name) }}</option>
            </select>
          </div>
          <div v-if="selectedCabsubject" class="col-sm-6">
                <span
                    class="badge badge-celcom-orange clickable-badge mt-3 mt-md-0"
                    @click="quitarCabsubject()"
                > {{ selectedCabsubject.name }} </span>
          </div>
        </div>
        <GrapesEditorEnvio
            v-if="bodyHtml=== ''"
            ref="grapesEditorEnvio"
            :response-tags="responseTags"
            @update-body-html="updateBodyHtml"
        />
        <div class="plantilla-msg" v-html="bodyHtml"></div>
        <div class="col-12 mt-3 form-check">
          <input
              id="activeGuardar"
              v-model="activeGuardar"
              class="form-check-input"
              type="checkbox"
              :disabled="deshabilitarGuardar"
          />
          <label class="form-check-label" for="activeGuardar">
            Desea Guardarlo como Plantilla
          </label>
        </div>
        <div
            v-if="activeGuardar"
            class="col-12 col-sm-6">
          <label>Nombre de la Plantilla</label>
          <argon-input
              id="programadaName"
              v-model="namePlantilla"
              class="multisteps-form__input"
              type="text"
              placeholder="Nombre de la campaña"
          />
          <button
              class="btn bg-gradient-celcom-orange mt-2 mb-0"
              :disabled="namePlantilla === ''"
              @click.prevent="addPlantilla">Guardar</button>
        </div>
      </div>
      <div v-if="switchValueMen === 'plantilla'" class="row mt-3">
        <label>Seleccionar Plantilla</label>
        <div class="">
          <select
              id="opcionesSelectDraft"
              v-model="selectedselectDraftValue"
              class="multisteps-form__select form-control form-select"
              name="opciones-ListCont"
              :disabled="selectedselectDraft.length > 0"
              @change="addToselectedselectDraft"
          >
            <option value="" disabled selected>Plantillas</option>
            <option v-for="option in $store.state.responsePlantillasEmail" :key="option.draftId" :value="option">{{option.name}}</option>
          </select>
        </div>
        <div class="col-12 mt-3">
          <card
              v-for="option in selectedselectDraft"
              :key="option.draftId"
              type="frame">
            <div class="btn-close-position">
              <button type="button" class="btn-close" aria-label="Close" @click="quitarToSelectedDraft(option.draftId)"></button>
            </div>
            <h5>Plantilla : {{option.name}}</h5>
            <div class="plantilla-msg" v-html="vistaPrevia"></div>
          </card>
        </div>
      </div>
      <div class="form-check">
        <br>
        <input
            v-model="configAvanzada"
            class="form-check-input"
            type="checkbox"
            id="configAvanzada"
            @change="limpiarAvanzada"
        />
        <label class="form-check-label" for="configAvanzada">
          Configuración Avanzada
        </label>
        <div v-if="configAvanzada" class="row config-avanzado">
          <!-- Usar Id de Seguimiento -->
          <div v-if="switchValueMen !== 'plantilla'" class="col-md-12">
            <div class="accordion-item">
              <h2 class="accordion-header" @click="toggleSection('seguimiento')">
                <button
                    :class="['accordion-button', { 'expanded': activeSection === 'seguimiento' }]"
                    type="button">
                  Usar Identificador de Referencia
                </button>
              </h2>
              <transition name="slide-fade">
              <div v-if="activeSection === 'seguimiento'" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <!-- Contenido expandido de Usar Id de Seguimiento -->
                  <label>Identificador de Referencia</label>
                  <div class="row">
                  <div class="col-sm-6">
                    <select
                        id="selectIdSeguimiento"
                        v-model="selectedIdSeguimiento"
                        class="multisteps-form__select form-control form-select"
                        :disabled="idSeguimientos.length > 1"
                        @change="agregarIdSeguimiento"
                    >
                      <option value="" disabled selected>Ids</option>
                      <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                              :key="cabIndex"
                              :value="cab"
                      >{{ traducirHeader(cab.name) }}</option>
                    </select>
                  </div>
                  <div class="col-sm-6">
                <span
                    v-for="id in idSeguimientos"
                    :key="id.name" class="badge badge-celcom-orange clickable-badge mt-3 mt-md-0"
                    @click="quitarIdSeguimiento(id)"
                > {{ traducirHeader(id.name) }} </span>
                  </div>
                  </div>
                </div>
              </div>
              </transition>
            </div>
          </div>

          <!-- Cantidad de SMS por tiempo -->
          <div class="col-md-12">
            <div class="accordion-item">
              <h2 class="accordion-header" @click="toggleSection('cantidad')">
                <button
                    :class="['accordion-button', { 'expanded': activeSection === 'cantidad' }]"
                    type="button">
                  Velocidad de envío de mensajes
                </button>
              </h2>
              <transition name="slide-fade">
              <div v-if="activeSection === 'cantidad'" class="accordion-collapse collapse show">
                <div class="accordion-body">
                  <div class="row">
                  <div class="col-sm-6">
                    <label>Unidad</label>
                    <argon-input
                        id="tiempoEnvio"
                        v-model.number="selectedTiempo.smsNumber"
                        class="multisteps-form__input"
                        type="number"
                        placeholder="Tiempo de envío"
                    />
                    <div v-if="!validarTiempo" :class="{ 'is-invalid-d': !validarTiempo }">
                      <p class="mb-0 text-sm">{{selectedTiempo.timeUnit === 'MINUTE' ? 'Solo se puede realizar envios de 1 a 300 correos por minutos' : 'Solo se puede realizar envios de 1 a 3600 correos por horas'}}</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label>Período</label>
                    <select
                        id="unidadEnvio"
                        v-model="selectedTiempo.timeUnit"
                        class="multisteps-form__select form-control form-select"
                    >
                      <option value="" disabled selected>Tipo</option>
                      <option value="MINUTE">Minutos</option>
                      <option value="HOUR">Hora</option>
                    </select>
                  </div>
                  </div>
                </div>
              </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="col-12 form-check">
        <input
            v-model="activeIdSeguimiento"
            class="form-check-input"
            type="checkbox"
            id="estadoCheckbox"
            @change="putFieldUrl"
        />
        <label class="form-check-label" for="estadoCheckbox">
          Usar Id de Seguimiento
        </label>
      </div>
      <div v-if="activeIdSeguimiento" class="row">
        <label>Id de Seguimiento</label>
        <div class="col-sm-6">
          <select
              id="selectIdSeguimiento"
              v-model="selectedIdSeguimiento"
              class="multisteps-form__select form-control form-select"
              :disabled="idSeguimientos.length > 1"
              @change="agregarIdSeguimiento"
          >
            <option value="" disabled selected>Ids</option>
            <option v-for="(cab, cabIndex) in $store.state.responseCustomer"
                    :key="cabIndex"
                    :value="cab"
            >{{ traducirHeader(cab.name) }}</option>
          </select>
        </div>
        <div class="col-sm-6">
              <span
                  v-for="id in idSeguimientos"
                  :key="id.name" class="badge badge-celcom-orange me-1 clickable-badge"
                  @click="quitarIdSeguimiento(id)"
              > {{ id.name }} </span>
        </div>
      </div>-->
    </div>
    <div class="card-footer border-1 mt-4 d-flex">
      <argon-button
        type="button"
        color="light"
        variant="gradient"
        class="js-btn-prev"
        @click="$parent.prevStep"
      ><i class="fas fa-chevron-left me-2"></i> Anterior
      </argon-button>
      <argon-button
      :disabled="!validarBoton"
        type="button"
        color="celcom"
        variant="gradient"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="nextStepValor"
      >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
    <Loader ref="loader" />
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import Choices from "choices.js";
import {validarRut, validarVacio} from "@/config/validaciones";
import ArgonInput from "@/components/ArgonInput.vue";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Emoji from "@/views/envios/whatsapp/Add/components/Emoji.vue";
import card from "@/views/envios/components/Card.vue";
import {encryptAES, getUser, getUserSub, horaAhora, traducir_header} from "@/config/servicios/campana/util";
import {addPlantilla} from "@/config/servicios/campana/envios/servicesEnvios";
import swal from "sweetalert2";
import GrapesEditorEnvio from "@/views/envios/components/email/GrapesEditorEnvio.vue";
import Loader from "@/components/Modal/Loader.vue";
export default {
  name: "ProgramadaEditEmailAdd2",
  components: {
    Loader,
    card,
    ArgonInput,
    ArgonButton,
    Emoji,
    GrapesEditorEnvio,
  },
  props: {
    sender: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sendingEditar: {
      type: Object,
      default: () => ({}),
    },
    editando: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateValues2', 'updateValues2Email'],
  data() {
    return {
      activeSection: null, // Controla qué sección está expandida
      opcionesAvanzadas: null,
      configAvanzada: false, // Simula la condición para mostrar el contenido avanzado
      switchValueMen: "redactar", // Simula el valor que tengas en tu $store o lógica
      selectedTiempo: {
        smsNumber: 0,
        timeUnit: "MINUTE",
      },

      bodyHtml: '',
      responseTags: {
        "category": "Cabeceras",
        "entries": []
      },
      vistaPrevia: null,
      activeGuardar: false,
      deshabilitarGuardar: false,
      namePlantilla: "",
      draftSingle: {
        draftId: null,
        name:"",
        subject: "",
        channelId: 3,
        message: "",
        whatsappSenderId: "",
        created: null,
        master: null,
      },

      selectedIdSeguimiento: '',
      activeIdSeguimiento: false,
      idSeguimientos: [],
      valueBody: true,
      editorContent: '', // Variable para almacenar el contenido del editor Quill
      textoCabecera: '',
      contHeader: 0,
      selectedCabsubject: '',
      selectedCab: '',
      asunto: false,

      selectedUrl: '',
      url_existente: false,
      short_url: "",
      urlValue: "",
      activeUrl: false,
      activeShort_url: false,
      selectedselectDraftValue: null,
      selectedTemplateValue: null,
      selectedPlanCont: "",
      message: '',
      rut: "",
      selectedselectDraft: [],
      selectedselectTemplate: [],
      selectedTag: "", // Nuevo dato para almacenar la opción seleccionada
      tags: "",       // Nuevo dato para almacenar los tags seleccionados
    };
  },
  computed: {
    validarAsunto() {
      return validarVacio(this.textoCabecera);
    },
    validarBoton() {
      if (this.validarAsunto && this.switchValueMen === "redactar") {
        return true
      }else if (this.switchValueMen === "plantilla" && this.selectedselectDraft.length > 0) {
        return true
      }else{
        return false
      }
    },
    validarTiempo() {
      if (this.selectedTiempo.timeUnit === 'MINUTE' && (this.selectedTiempo.smsNumber < 1 || this.selectedTiempo.smsNumber > 300)) {
        return false // Devuelve true si está fuera del rango para minutos
      } else if (this.selectedTiempo.timeUnit === 'HOUR' && (this.selectedTiempo.smsNumber < 1 || this.selectedTiempo.smsNumber > 3600)) {
        return false; // Devuelve true si está fuera del rango para horas
      } else {
        return true; // Si está dentro del rango
      }
    },
    validarRut() {
      return validarRut(this.rut);
    },
    validarVacios() {
      return validarVacio(this.tags);
    },
  },
  watch: {
    textoCabecera() {
      if (this.textoCabecera && !this.textoCabecera.includes('{' + this.selectedCabsubject.name + '}')) {
        this.contHeader = 0;
        this.selectedCabsubject = '';
      }
      //this.senderHeader = newValue;
    },

    sendingEditar: {
      handler(newValidForm) {
        this.textoCabecera = newValidForm.subject;
        const regex = /\{(.*?)\}/;
        const match = this.textoCabecera ? this.textoCabecera.match(regex) : null;

        if (match) {
          const contenido = match[1]; // Contenido dentro de {}
          // Realiza las acciones necesarias con el contenido
          console.log('Contenido dentro de {}:', contenido);
          const cabe = this.$store.state.responseCustomer.find((cab) => cab.name === contenido);
          if (cabe) {
            this.selectedCabsubject = cabe;
            this.contHeader += 1;
            this.asunto = false;
          }
        }
        if (newValidForm.messageType === 1) {
          this.editorContent = newValidForm.message;
        }else if (newValidForm.messageType === 2) {
          if (newValidForm.channelId === 3 && this.$store.state.responsePlantillasEmail.length > 0){
            const draft = this.$store.state.responsePlantillasEmail.find((draft) => draft.draftId === newValidForm.draftFlexId);
            this.selectedselectDraft.push(draft);
            this.selectedselectDraftValue = draft;
            this.switchValueMen = "plantilla";
          }
        }

        if (newValidForm.trackId && newValidForm.trackId.length > 0) {
          this.activeIdSeguimiento = true;
          newValidForm.trackId.forEach((id) => {
            this.idSeguimientos.push(id);
          });
        }
        // Actualiza otras propiedades según sea necesario
      },
      immediate: true,
      deep: true,
    },
  },

  async mounted() {
    await this.$store.getters.arrayCabeceras();
    await this.$nextTick();
    this.$refs.grapesEditorEnvio.clearEditor();
    this.llenarTags();
    if (document.getElementById("opcionesTags")) {
      var element = document.getElementById("opcionesTags");
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
      });
    }
  },
  methods: {
    toggleSection(section) {
      this.activeSection = this.activeSection === section ? null : section;
    },
    llenarTags(){
      this.$store.state.responseCustomer.forEach((tag) => {
        this.responseTags.entries.push(
            {
              "label": traducir_header(tag.name),
              "value": "{"+tag.name+"}",
            });
      });
    },
    updateBodyHtml(data) {
      this.active = false;
      this.bodyHtml = data.vista;
      this.editorContent = data;
    },
    async cargarDatos(){
      await this.$store.getters.plantillasEmailGet();
      this.namePlantilla = "";
      this.activeGuardar = false;
      this.deshabilitarGuardar = true;
    },
    async addPlantilla() {
      try {
        this.$refs.loader.showLoader();
        this.$refs.grapesEditorEnvio.saveTemplateMethod();
        const tiempoActual = new Date().getTime();
        this.draftSingle.name = this.namePlantilla;
        this.draftSingle.subject = this.textoCabecera;
        this.draftSingle.message = JSON.stringify(this.editorContent);
        this.draftSingle.created = tiempoActual;

        if(this.draftSingle.name==="" || this.draftSingle.message===""){
          console.log("Error debe completar los datos requeridos");
        }
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer)),
          draft: encodeURIComponent(JSON.stringify(this.draftSingle)),
          short_url: this.short_url ? encodeURIComponent(JSON.stringify(this.short_url)) : encodeURIComponent(JSON.stringify({})),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await addPlantilla(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          console.log("Plantilla Add");
          this.response = response.data.data;
          this.$refs.loader.hideLoader();
          swal.fire({
            text: '¡Plantilla creada exitosamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
          })
          this.cargarDatos();
        } else {
          this.$refs.loader.hideLoader();
          swal.fire({
            text: '¡No se pudo crear la Plantilla!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            icon: 'error',
          })
        }
      } catch (error) {
        this.$refs.loader.hideLoader();
        swal.fire({
          text: '¡No se pudo crear la Plantilla!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          icon: 'error',
        })
      }
    },
    traducirHeader(name){
      return traducir_header(name);
    },
    quitarIdSeguimiento(id) {
      this.idSeguimientos = this.idSeguimientos.filter((item) => item !== id);
    },
    quitarCabsubject() {
      this.contHeader = 0;
      this.selectedCabsubject = '';
      const regex = /\{.*?\}/g;
      if (this.textoCabecera) {
        this.textoCabecera = this.textoCabecera.replace(regex, '').trim();
      }
    },
    quitarToSelectedDraft(draftId) {
      this.selectedselectDraft = this.selectedselectDraft.filter((item) => item.draftId !== draftId);
      this.selectedselectDraftValue = null;
    },
    agregarIdSeguimiento() {
      this.idSeguimientos.push(this.selectedIdSeguimiento);
    },
    limpiar() {
      this.message = "";
      this.selectedselectDraft = [];
      this.selectedselectTemplate = [];
      this.short_url = "";
      this.urlValue = "";
      this.activeUrl = false;
      this.activeShort_url = false;
      this.selectedCab = "";
      this.selectedselectDraftValue = null;
      this.selectedTemplateValue = null;
      this.selectedTag = "";
      this.tags = "";
    },
    handleEmojiSelected(emoji) {
      this.textoCabecera = this.textoCabecera + emoji;
    },
    addVariable() {
      if (this.contHeader === 0) {
        this.contHeader += 1;
        this.asunto = true;
      }
    },
    putFieldSubject() {
      this.textoCabecera = this.textoCabecera + '{' + this.selectedCabsubject.name + '}';
      this.asunto = false;
    },

    async nextStepValor() {
      if (this.switchValueMen === 'redactar') {
        await this.$refs.grapesEditorEnvio.saveTemplateMethod();
      }
      // Puedes emitir un evento para notificar al componente padre sobre los cambios
      this.$emit('updateValues2Email', {
        message: JSON.stringify(this.editorContent),
        subjet: this.textoCabecera,
        selectDraft: this.selectedselectDraft,
        selectTemplate: this.selectedselectTemplate,
        idSeguimiento: this.idSeguimientos,
        cantidadSmsTiempo: this.selectedTiempo,
      });
      this.$parent.nextStep();
    },

    urlExistente() {
      this.url_existente = !this.url_existente;
    },
    getUrlExistente() {
      this.short_url = this.selectedUrl;
    },
    async getShort_url() {
      this.short_url = await this.$store.getters.add_short_urls(this.urlValue);
    },
    putFieldUrl() {
      if (this.activeUrl) {
        this.message += "{URL}";
      } else {
        // Quitar "{URL}" del mensaje
        this.message = this.message.replace("{URL}", "");
      }
    },

    addToselectedselectDraft() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo

      if (this.selectedselectDraft.indexOf(this.selectedselectDraftValue) === -1) {
        this.selectedselectDraft.push(this.selectedselectDraftValue);
      }
      const project = JSON.parse(this.selectedselectDraftValue.message);
      this.vistaPrevia = project.vista;
    },
    addToselectedTemplate() {
      // Verificar si el valor ya está en el arreglo antes de agregarlo

      if (this.selectedselectTemplate.indexOf(this.selectedTemplateValue) === -1) {
        this.selectedselectTemplate.push(this.selectedTemplateValue);
      }
      console.log(JSON.stringify(this.selectedTemplateValue));
    },
    onSwitchChange(event) {
      this.switchValueMen = event.target.checked;
    },
    validarVacio,
    updateTags() {
      // Agregar el tag seleccionado al string de tags
      if (this.selectedTag && this.selectedTag !== "..." && !this.tags.includes(this.selectedTag)) {
        if (this.tags.length > 0) {
          this.tags += `, ${this.selectedTag}`;
        } else {
          this.tags = this.selectedTag;
        }
      }
    },
  },
};
</script>
<style>
#vistaMensaje2 p {
  font-size: 0.875rem;
}
#vistaMensaje2 img {
  max-width: 100%;
  height: auto;
}
.clickable-badge {
  cursor: pointer; /* Cambia el cursor a una mano para indicar interactividad */
  transition: background-color 0.3s; /* Suaviza la transición de colores */
}

.clickable-badge:hover {
  background-color: #f8d7da; /* Cambia el color de fondo al pasar el mouse */
}
.plantilla-msg p {
  font-size: 0.875rem;
}
.plantilla-msg img {
  max-width: 100%;
  height: auto;
}
.btn-close-position {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.config-avanzado .accordion-item {
  margin-bottom: 10px;
  border: 1px solid #ddd;
}

.config-avanzado .accordion-button:hover {
  background-color: #eaeaea;
}

.config-avanzado .accordion-button:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  content: "\f106";
  margin-left: auto;
  color: rgba(33, 37, 41, 0.5);
  transition: all 0.2s ease-in-out;
}
.config-avanzado .accordion-button.expanded:after {
  content: "\f107"; /* Ícono alternativo cuando se expande */
}
.config-avanzado .accordion-body {
  padding: 15px;
  background-color: #eaeaea;
  border: 0;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-from, .slide-fade-leave-to /* .slide-fade-leave-active en Vue 2 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.switch-url.form-switch .form-check-input:after {
  width: 1rem !important;
  height: 1rem !important;
}
</style>