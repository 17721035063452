<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12 col-lg-10 mx-auto mb-4">
        <domain-email-campanna-table :domain-email-data="responseDomainEmail" :is-active="isActive"/>
      </div>
    </div>
      <modal :is-visible="modalAddSender" close-modal="" class="modal-xl">
        <form>
          <h6 class="heading-small text-muted mb-4">Información de Remitente</h6>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-label">Nombre de Remitente</label>
              <argon-input
                  v-model="fromName"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre Remitente"
              />
            </div>
            <div class="col-lg-6">
              <label class="form-label">Correo de Remitente</label>
              <argon-input
                  ref="emailInput"
                  v-model="emailFrom"
                  class="multisteps-form__input"
                  type="email"
                  placeholder="Correo Remitente"
                  @input="agregarDomain"
              />
              <div v-if="!emailsValid" :class="{ 'is-invalid-d': !emailsValid }">
                <p class="mb-0 text-sm">Ingresa un correo electrónico válido</p>
              </div>
            </div>
          </div>
          <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
            <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click.prevent="addSenderEmail">Agregar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Cancelar</button>
          </div>
          <div class="pb-0 card-body mb-0">
            <input v-model="searchSender" type="text" class="form-control mt-2" placeholder="Buscar...">
          </div>
          <div class="card-body table-responsive">
            <table class="table align-items-center mb-0">
              <thead>
              <tr>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Nombre de ...
                </th>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Correo de ...
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Acción
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in filteredData" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{row.fromName}}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="mb-0 text-sm">{{ row.emailFrom }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <a
                      class="btn btn-info m-0 me-2" href="#!" data-toggle="tooltip" data-original-title="Permisos"
                     @click.prevent="openModalEditSender(row)">
                    <i class="fas fa-edit"></i>
                  </a>
                  <a
                      class="btn btn-danger m-0" href="#!" data-toggle="tooltip" data-original-title="Eliminar sender"
                     @click.prevent="openModalEliminarSender(row)">
                    <i class="fas fa-trash"></i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </form>

      </modal>
       <modal :is-visible="modalAddDomain" close-modal="">
        <form>
          <h6 class="heading-small text-muted mb-4">Información del Dominio</h6>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-label">Ingrese Dominio</label>
              <argon-input
                  v-model="data.domainName"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre Remitente"
              />
            </div>
            <div v-if="!isActive" class="col-lg-6">
              <label class="form-label">Ingrese inb</label>
              <argon-input
                  v-model="data.inboundRoutingText"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre Remitente"
              />
            </div>
          </div>
          <div v-if="!isActive" class="row">
            <div class="col-lg-6">
              <label class="form-label">Ingrese Path</label>
              <argon-input
                  v-model="data.returnPathText"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre Remitente"
              />
            </div>
            <div class="col-lg-6">
              <label class="form-label">Ingrese Tracking</label>
              <argon-input
                  v-model="data.customTrackingText"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Nombre Remitente"
              />
            </div>
          </div>
          <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
            <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click.prevent="addDomianEmail">Agregar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Cancelar</button>
          </div>
        </form>

      </modal>
      <modal :is-visible="modalEditSender" close-modal="">
        <form>
          <h6 class="heading-small text-muted mb-4">Datos del Remitente</h6>
          <div>
            <div class="row">
              <div class="col-lg-6">
                <label class="form-label">Nombre Remitente</label>
                <argon-input
                    v-model="dataSender.fromName"
                    placeholder="Nombre Remitente"
                    class="multisteps-form__input"
                    type="text"
                />
              </div>
              <div class="col-lg-6">
                <label class="form-label">Correo Remitente</label>
                <argon-input
                    v-model="dataSender.emailFrom"
                    placeholder="Correo Remitente"
                    class="multisteps-form__input"
                    type="email"
                />
              </div>
            </div>
            <div class="modal-footer px-0 mt-lg-4 d-flex justify-content-between">
              <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click.prevent="editSenderEmail">Editar</button>
              <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Cancelar</button>
            </div>
          </div>
        </form>
      </modal>
      <modal-delete :is-visible="modalEliminarSender" close-modal="">
        <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
        <div class="mt-lg-4 d-flex justify-content-between">
          <button class="btn bg-gradient-danger mt-2 mb-0" @click="borrarSender">Eliminar</button>
          <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
        </div>
      </modal-delete>
      <modal-delete :is-visible="modalEliminarDomain" close-modal="">
      <p class="text-center"><b>¿Estás seguro de que deseas eliminar?</b></p>
      <div class="mt-lg-4 d-flex justify-content-between">
        <button class="btn bg-gradient-danger mt-2 mb-0" @click="borrarDomain">Eliminar</button>
        <button class="btn bg-gradient-dark mt-2 mb-0" @click="closeModal">Cancelar</button>
      </div>
    </modal-delete>
      <Loader ref="loader" />
  </div>
</template>

<script>
//import SenderEmailCampannaTable from "./components/SenderEmailCampannaTable.vue";
import DomainEmailCampannaTable from "@/views/campannas/Sender/components/DomainEmailCampannaTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Modal from "@/components/Modal/Modal.vue";
import {encryptAES, getUser, getUserSub, horaAhora} from "@/config/servicios/campana/util";
import swal from "sweetalert2";
import ModalDelete from "@/components/Modal/ModalDelete.vue";
import {sender_add_email, sender_delete_email, sender_edit_email} from "@/config/servicios/campana/sender/servicesSenderEmail";
import {addDomainEmail, statusDomainEmail, deleteDomainEmail} from "@/config/servicios/campana/Domain/domainServices";
import {isEmailValid} from "@/config/validaciones";
import IconMailPlus from '@/assets/iconos/mail-plus.svg';
import Loader from "@/components/Modal/Loader.vue";

export default {
  name: "SenderEmailCampanna",
  components: {
    Loader,
    ModalDelete,
    Modal, ArgonInput,
    //SenderEmailCampannaTable,
    DomainEmailCampannaTable,
  },
  data() {
    return {
      responseSenderEmail: [],
      responseDomainEmail: [],
      responseAddDomain: null,
      dataSender: {},
      dataDomain: {},
      modalAddSender: false,
      modalAddDomain: false,
      modalEditSender: false,
      modalEliminarSender: false,
      modalEliminarDomain: false,
      fromName: '',
      emailFrom: '',
      searchSender: "", // Variable para almacenar la entrada del buscador
      data: {
        customerDomainId: null,
        customerId: null,
        domainName: "",
        externalDomainId: null,
        isVerified: false,
        isDnsActive: false,
        spf: false,
        dkim: false,
        mx: false,
        tracking: false,
        cname: false,
        rpCname: false,
        dkimText: null,
        spfText: null,
        inboundRoutingText: null,
        returnPathText: null,
        customTrackingText: null
      },
      fromNameDomain: '',
      validarExisteDominio: false,
    };
  },
  computed: {
    isActive() {
      const estadoCanales = this.$store.state.estado_canales || [];
      return estadoCanales.some(canal => {
        return (
            canal.channel.channel_name === "EMAIL" &&
            canal.channel.active &&
            canal.active &&
            canal.emailProvider === "SENDGRID"
        );
      });
    },
    emailsValid() {
      return isEmailValid(this.emailFrom);
    },
    filteredData() {
      if (!Array.isArray(this.responseSenderEmail)) {
        return [];
      }

      // Filtra primero por customerDomainId
      const filteredByDomainId = this.responseSenderEmail.filter(sender =>
          sender.customerDomainId === this.dataDomain.customerDomainId
      );

      // Luego, aplica el filtro de búsqueda en fromName y emailFrom
      return filteredByDomainId.filter(sender => {
        const fromName = sender.fromName ?? '';
        const emailFrom = sender.emailFrom ?? '';

        return (
            fromName.toLowerCase().includes(this.searchSender.toLowerCase()) ||
            emailFrom.toLowerCase().includes(this.searchSender.toLowerCase())
        );
      });
    },
  },
  mounted() {
    this.obtenerSenderEmails();
    this.obtenerDomainEmails().then(() => {
      // Verificar si no hay dominios configurados
      if (this.responseDomainEmail.length > 0) {
        this.mostrarModalInformativoExisteDominio();
      }else if (this.responseDomainEmail.length === 0) {
        this.mostrarModalInformativo();
      }
    });
  },
  methods: {
    validarDns(isVerified, isDnsActive) {
      return !!(isVerified && isDnsActive);
    },
    async mostrarModalInformativo() {
      await swal.fire({
        title: 'Configuración de Dominio Requerida',
        text: 'Para agregar un remitente, primero debe tener un dominio configurado.',
        icon: 'info',
        confirmButtonText: 'Entendido',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-celcom-blue', // Ajusta esta clase según tu estilo
        },
      });
    },
    async mostrarModalInformativoExisteDominio() {
      await swal.fire({
        title: 'Configuración de Remitente',
        html: `
      <div style="text-align: center;">
        <span>Para agregar un remitente luego de tener validado algun <b>Dominio</b>, debe hacer click encima del icono.</span>
        <br />
        <img src="${IconMailPlus}" alt="Icono de agregar remitente"
          style="width: 24px; height: 24px; margin-top: 10px; filter: invert(27%) sepia(93%) saturate(5378%) hue-rotate(190deg) brightness(92%) contrast(101%);" />
      </div>
    `,
        icon: 'info',
        confirmButtonText: 'Entendido',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-celcom-blue',
        },
      });
    },
    agregarDomain() {
      if (!this.dataDomain || !this.dataDomain.domainName) return;

      const inputElement = this.$refs.emailInput.$el.querySelector('input');
      const cursorPos = inputElement.selectionStart; // Posición actual del cursor

      // Verificar si el dominio ya existe en `emailFrom`
      if (!this.emailFrom.includes("@" + this.dataDomain.domainName)) {
        // Concatenar el dominio al correo actual
        this.emailFrom = this.emailFrom.slice(0, cursorPos) + "@" + this.dataDomain.domainName;

        this.$nextTick(() => {
          // Cambia temporalmente el tipo a 'text' para restaurar el cursor
          inputElement.type = 'text';
          // Restaura el cursor inmediatamente después del usuario y antes del dominio
          inputElement.setSelectionRange(cursorPos, cursorPos);

          // Vuelve a establecer el tipo en 'email'
          inputElement.type = 'email';
          inputElement.focus();
        });
      }
    },
    async obtenerSenderEmails(){
      this.responseSenderEmail = await this.$store.getters.obtenerSenderEmail();
    },
    async obtenerDomainEmails(){
      this.responseDomainEmail = await this.$store.getters.obtenerDomainEmail();
    },
    closeModal() {
      this.modalAddSender = false;
      this.modalAddDomain = false;
      this.modalEditSender = false;
      this.modalEliminarSender = false;
      this.modalEliminarDomain = false;
      this.fromName = '';
      this.emailFrom = '';
      this.data = {
        customerDomainId: null,
        customerId: null,
        domainName: "",
        externalDomainId: null,
        isVerified: false,
        isDnsActive: false,
        spf: false,
        dkim: false,
        mx: false,
        tracking: false,
        cname: false,
        rpCname: false,
        dkimText: null,
        spfText: null,
        inboundRoutingText: null,
        returnPathText: null,
        customTrackingText: null
      };
    },

    //Inicio de agregar un SenderEmail
    openModalAddSender(row) {
      this.dataDomain = JSON.parse(JSON.stringify(row));
      this.modalAddSender = true;
    },
    async addSenderEmail() {
      let self = this;
      let user = getUserSub() ?? getUser();

      // Verificación de campos obligatorios
      if (this.fromName === "" || this.emailFrom === "") {
        await swal.fire({
          title: "Error: Debe completar todos los campos",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          icon: 'error',
        });
        return false;
      }

      // Configuración del remitente
      let sender = {
        emailSenderId: null,
        emailFrom: self.emailFrom,
        fromName: self.fromName,
        customerDomainId: self.dataDomain.customerDomainId,
      };

      // Construcción del objeto de solicitud
      let js = {
        time_expired: horaAhora(),
        sender: encodeURIComponent(JSON.stringify(sender)),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
      };
      let body = new URLSearchParams();
      body.append('TOKEN', encryptAES(js));

      // Llamada al servicio de agregar remitente
      try {
        const response = await sender_add_email(body);
        if (response.data.response === "200") {
          self.responseSenderEmail = response.data.data;

          // Notificación de éxito sin cerrar el modal
          swal.fire({
            title: '¡Remitente agregado con éxito!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            icon: 'success',
            timer: 2000, // Cierra la notificación automáticamente después de 2 segundos
            showConfirmButton: false, // No muestra botón de confirmación
          });
          this.fromName = '';
          this.emailFrom = '';
        } else {
          console.log("No se pudo agregar el remitente");
          swal.fire({
            title: 'No se pudo agregar el remitente',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-orange',
            },
            icon: 'error',
          });
        }
      } catch (error) {
        console.log("No se pudo agregar el remitente", error);
        swal.fire({
          title: 'No se pudo agregar el remitente',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          icon: 'error',
        });
      }
    },
    //Fin de agregar un SenderEmail

    //Inicio de editar un SenderEmail
    openModalEditSender(row) {
      this.dataSender = JSON.parse(JSON.stringify(row));
      this.modalEditSender = true;
    },
    async editSenderEmail(){
      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      let js = {
        "time_expired":horaAhora(),
        "sender":encodeURIComponent(JSON.stringify(this.dataSender)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }
      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));
      await sender_edit_email(body)
          .then(function (response) {
            if (response.data.response === "200") {

              self.responseSenderEmail = response.data.data;
              self.modalEditSender = false;

              swal.fire({
                title: '¡Remitente editado con éxito!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                type: 'success',
                icon: 'success',
              })

            } else {
              console.log("No se pudo agregar el remitente");
              swal.fire({
                title: 'No se pudo editar el remitente',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            }
          })
          .catch(function (error) {
            console.log("No se pudo agregar el remitente",error);
            swal.fire({
              title: 'No se pudo editar el remitente',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });
    },
    //Fin de editar un SenderEmail

    //Inicio de Eliminar un SenderEmail
    openModalEliminarSender(row) {
      this.dataSender = JSON.parse(JSON.stringify(row));
      this.modalEliminarSender = true;
    },
    async borrarSender() {
      let self = this;
      let user = getUser();

      let js = {
        "time_expired":horaAhora(),
        "sender":encodeURIComponent(JSON.stringify(self.dataSender)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await sender_delete_email(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.responseSenderEmail = response.data.data;
              self.modalEliminarSender = false;
            } else {
              console.log("No se pudo borrar remitente");
              swal.fire({
                title: 'No se pudo borrar el remitente',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            }

          })
          .catch(function (error) {
            console.log("No se pudo borrar el remitente",error);
            swal.fire({
              title: 'No se pudo borrar el remitente',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });
    },
    //Fin de Eliminar un SenderEmail



    //Inicio de agregar un DomainEmail
    openModalAddDomain() {
      this.modalAddDomain = true;
    },
    async addDomianEmail(){
      this.$refs.loader.showLoader();
      if (this.responseDomainEmail.length === 0){
        this.validarExisteDominio = true;
      }
      let self = this;
      let user = (getUserSub()!=null) ? getUserSub() :getUser();

      if(this.data.domainName === ""){
        self.$refs.loader.hideLoader();
        await swal.fire({
          title: "Error Debe completar todos los campos",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          icon: 'error',
        })
        return false;
      }

      this.data.customerId = user.customer.customerId;
      let js = {
        "time_expired":horaAhora(),
        "customerDomain":encodeURIComponent(JSON.stringify(this.data)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }
      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));
      await addDomainEmail(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.responseAddDomain = response.data.data;
              // Solo parsear las propiedades que son cadenas JSON
              self.responseAddDomain.dkimText = JSON.parse(self.responseAddDomain.dkimText);
              self.responseAddDomain.spfText = JSON.parse(self.responseAddDomain.spfText);
              self.responseAddDomain.inboundRoutingText = JSON.parse(self.responseAddDomain.inboundRoutingText);
              self.responseAddDomain.returnPathText = JSON.parse(self.responseAddDomain.returnPathText);
              self.responseAddDomain.customTrackingText = JSON.parse(self.responseAddDomain.customTrackingText);
              self.modalAddDomain = false;
              self.$refs.loader.hideLoader();
              swal.fire({
                title: '¡Dominio agregado con éxito!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-blue',
                },
                icon: 'success',
              }).then((result) => {
                if (result.isConfirmed) {
                  swal.fire({
                    title: 'Configuración DNS necesaria',
                    html: `
    <style>
      .dns-table {
        width: 100%;
        border-collapse: collapse;
      }
      .dns-table th, .dns-table td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #e0e0e0;
      }
      .dns-table th:first-child, .dns-table td:first-child {
        width: 150px;
      }
      .dns-table th:last-child, .dns-table td:last-child {
        width: 100px;
        text-align: center;
      }
       .dns-table th:last-child, .dns-table td:last-child {
        width: 120px; /* Ancho fijo para la columna de Acción */
        text-align: center;
      }
      .copy-btn {
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 4px 8px;
        cursor: pointer;
      }
    </style>
    <p>Agregar los siguientes valores al DNS para completar la validación del dominio:</p>

    <div class="dns-title">DKIM</div>
    <table class="dns-table">
      <tr class="dns-header">
        <th>Propiedad</th>
        <th>Valor</th>
        <th>Acción</th>
      </tr>
      <tr>
        <td>Hostname</td>
        <td id="dkim-hostname">${self.responseAddDomain.dkimText?.hostname || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="dkim-hostname">Copiar</button></td>
      </tr>
      <tr>
        <td>Type</td>
        <td id="dkim-type">${self.responseAddDomain.dkimText?.type || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="dkim-type">Copiar</button></td>
      </tr>
      <tr>
        <td>Value</td>
        <td id="dkim-value">${self.responseAddDomain.dkimText?.value || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="dkim-value">Copiar</button></td>
      </tr>
    </table>
    <div class="dns-title">SPFTEXT</div>
    <table class="dns-table">
      <tr class="dns-header">
        <th>Propiedad</th>
        <th>Valor</th>
        <th>Acción</th>
      </tr>
      <tr>
        <td>Hostname</td>
        <td id="spfText-hostname">${self.responseAddDomain.spfText?.hostname || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="spfText-hostname">Copiar</button></td>
      </tr>
      <tr>
        <td>Type</td>
        <td id="spfText-type">${self.responseAddDomain.spfText?.type || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="spfText-type">Copiar</button></td>
      </tr>
      <tr>
        <td>Value</td>
        <td id="spfText-value">${self.responseAddDomain.spfText?.value || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="spfText-value">Copiar</button></td>
      </tr>
    </table>
     <div class="dns-title">DOMAIN SERVER</div>
    <table class="dns-table">
      <tr class="dns-header">
        <th>Propiedad</th>
        <th>Valor</th>
        <th>Acción</th>
      </tr>
      <tr>
        <td>Hostname</td>
        <td id="spfText-hostname">${self.responseAddDomain.returnPathText?.hostname || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="spfText-hostname">Copiar</button></td>
      </tr>
      <tr>
        <td>Type</td>
        <td id="spfText-type">${self.responseAddDomain.returnPathText?.type || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="spfText-type">Copiar</button></td>
      </tr>
      <tr>
        <td>Value</td>
        <td id="spfText-value">${self.responseAddDomain.returnPathText?.value || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="spfText-value">Copiar</button></td>
      </tr>
    </table>
  `,
                    icon: 'info',
                    width: '50%',
                    confirmButtonText: 'Aceptar',
                    buttonsStyling: false,
                    customClass: {
                      confirmButton: 'btn btn-celcom-blue',
                    },
                    willOpen: () => {
                      document.querySelectorAll('.copy-btn').forEach(button => {
                        button.addEventListener('click', () => {
                          const elementId = button.getAttribute('data-copy-id');
                          const value = document.getElementById(elementId).innerText;

                          if (navigator.clipboard && navigator.clipboard.writeText) {
                            // Intentar copiar usando la API del portapapeles si está disponible
                            navigator.clipboard.writeText(value).then(() => {
                              button.innerText = 'Copiado';
                              button.disabled = true;
                              setTimeout(() => {
                                button.innerText = 'Copiar';
                                button.disabled = false;
                              }, 1500);
                            }).catch(err => {
                              console.error('Error al copiar:', err);
                            });
                          } else {
                            // Método alternativo para copiar si la API no está disponible
                            const tempInput = document.createElement('input');
                            tempInput.value = value;
                            document.body.appendChild(tempInput);
                            tempInput.select();
                            document.execCommand('copy');
                            document.body.removeChild(tempInput);

                            button.innerText = 'Copiado';
                            button.disabled = true;
                            setTimeout(() => {
                              button.innerText = 'Copiar';
                              button.disabled = false;
                            }, 1500);
                          }
                        });
                      });
                    }
                  }).then(() => {
                    if (self.validarExisteDominio){
                      self.mostrarModalInformativoExisteDominio();
                    }
                  });
                }
              });
              self.obtenerDomainEmails();
              self.closeModal();
            } else {
              let mensaje = "No se pudo agregar el Dominio";
              let icono = "error";
              console.log("No se pudo agregar el Dominio");
              if (response.data.message.includes("Sender already exists")) {
                mensaje = "Este dominio ya esta en uso";
                icono = "warning";
              }
              self.$refs.loader.hideLoader();
              swal.fire({
                title: mensaje,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: icono,
              })
            }
          })
          .catch(function (error) {
            console.log("No se pudo agregar el Dominio",error);
            self.$refs.loader.hideLoader();
            swal.fire({
              title: 'No se pudo agregar el Dominio',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });
    },
    //Fin de agregar un DomainEmail
    isValidJSON(jsonString) {
      try {
        JSON.parse(jsonString);
        return true; // Es un JSON válido
      } catch (error) {
        return false; // No es un JSON válido
      }
    },
    //Inicio de status de DomainEmail
    async statusDomainEmail(row){
      this.$refs.loader.showLoader();
        let self = this;
        let user = (getUserSub() != null) ? getUserSub() : getUser();

        let js = {
          "time_expired": horaAhora(),
          "customerDomain": encodeURIComponent(JSON.stringify(row)),
          "customer": encodeURIComponent(JSON.stringify(user.customer)),
        }
        var body = new URLSearchParams();
        body.append('TOKEN', (encryptAES(js)));
        await statusDomainEmail(body)
            .then(function (response) {
              if (response.data.response === "200" && !self.validarDns(response.data.data.isVerified, response.data.data.isDnsActive)) {
                self.responseAddDomain = response.data.data;
                // Solo parsear las propiedades que son cadenas JSON
                self.responseAddDomain.dkimText = self.isValidJSON(self.responseAddDomain.dkimText) ? JSON.parse(self.responseAddDomain.dkimText) : self.responseAddDomain.dkimText;
                self.responseAddDomain.spfText = self.isValidJSON(self.responseAddDomain.spfText) ? JSON.parse(self.responseAddDomain.spfText) : self.responseAddDomain.spfText;
                self.responseAddDomain.inboundRoutingText = self.isValidJSON(self.responseAddDomain.inboundRoutingText) ? JSON.parse(self.responseAddDomain.inboundRoutingText) : self.responseAddDomain.inboundRoutingText;
                self.responseAddDomain.returnPathText = self.isValidJSON(self.responseAddDomain.returnPathText) ? JSON.parse(self.responseAddDomain.returnPathText) : self.responseAddDomain.returnPathText;
                self.responseAddDomain.customTrackingText = self.isValidJSON(self.responseAddDomain.customTrackingText) ? JSON.parse(self.responseAddDomain.customTrackingText) : self.responseAddDomain.customTrackingText;
                self.$refs.loader.hideLoader();
                self.obtenerDomainEmails();
                swal.fire({
                  title: 'Configuración DNS necesaria',
                  html: `
  <style>
    .dns-table {
      width: 100%;
      border-collapse: collapse;
    }
    .dns-table th, .dns-table td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
    }
    .dns-table th:first-child, .dns-table td:first-child {
      width: 150px;
    }
    .dns-table th:last-child, .dns-table td:last-child {
      width: 120px; /* Ancho fijo para la columna de Acción */
      text-align: center;
    }
    .dns-title {
      font-weight: bold;
      font-size: 1.2em;
      margin-top: 20px;
      margin-bottom: 10px;
      color: #333;
    }
    .copy-btn {
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 4px 8px;
      cursor: pointer;
    }
  </style>
  <p>Agregar los siguientes valores al DNS para completar la validación del dominio:</p>

  <div class="dns-title">DKIM</div>
  <table class="dns-table">
    <tr class="dns-header">
      <th>Propiedad</th>
      <th>Valor</th>
      <th>Acción</th>
    </tr>
    <tr>
      <td>Hostname</td>
      <td id="dkim-hostname">${self.responseAddDomain.dkimText?.hostname || 'Valor no disponible'}</td>
      <td><button class="copy-btn" data-copy-id="dkim-hostname">Copiar</button></td>
    </tr>
    <tr>
      <td>Type</td>
      <td id="dkim-type">${self.responseAddDomain.dkimText?.type || 'Valor no disponible'}</td>
      <td><button class="copy-btn" data-copy-id="dkim-type">Copiar</button></td>
    </tr>
    <tr>
      <td>Value</td>
      <td id="dkim-value">${self.responseAddDomain.dkimText?.value || 'Valor no disponible'}</td>
      <td><button class="copy-btn" data-copy-id="dkim-value">Copiar</button></td>
    </tr>
  </table>

  <div class="dns-title">SPF</div>
  <table class="dns-table">
    <tr class="dns-header">
      <th>Propiedad</th>
      <th>Valor</th>
      <th>Acción</th>
    </tr>
    <tr>
      <td>Hostname</td>
      <td id="spfText-hostname">${self.responseAddDomain.spfText?.hostname || 'Valor no disponible'}</td>
      <td><button class="copy-btn" data-copy-id="spfText-hostname">Copiar</button></td>
    </tr>
    <tr>
      <td>Type</td>
      <td id="spfText-type">${self.responseAddDomain.spfText?.type || 'Valor no disponible'}</td>
      <td><button class="copy-btn" data-copy-id="spfText-type">Copiar</button></td>
    </tr>
    <tr>
      <td>Value</td>
      <td id="spfText-value">${self.responseAddDomain.spfText?.value || 'Valor no disponible'}</td>
      <td><button class="copy-btn" data-copy-id="spfText-value">Copiar</button></td>
    </tr>
  </table>
  <div class="dns-title">DOMAIN SERVER</div>
    <table class="dns-table">
      <tr class="dns-header">
        <th>Propiedad</th>
        <th>Valor</th>
        <th>Acción</th>
      </tr>
      <tr>
        <td>Hostname</td>
        <td id="spfText-hostname">${self.responseAddDomain.returnPathText?.hostname || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="spfText-hostname">Copiar</button></td>
      </tr>
      <tr>
        <td>Type</td>
        <td id="spfText-type">${self.responseAddDomain.returnPathText?.type || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="spfText-type">Copiar</button></td>
      </tr>
      <tr>
        <td>Value</td>
        <td id="spfText-value">${self.responseAddDomain.returnPathText?.value || 'Valor no disponible'}</td>
        <td><button class="copy-btn" data-copy-id="spfText-value">Copiar</button></td>
      </tr>
    </table>
`,
                  icon: 'info',
                  width: '50%',
                  confirmButtonText: 'Aceptar',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-blue',
                  },
                  willOpen: () => {
                    document.querySelectorAll('.copy-btn').forEach(button => {
                      button.addEventListener('click', () => {
                        const elementId = button.getAttribute('data-copy-id');
                        const value = document.getElementById(elementId).innerText;

                        if (navigator.clipboard && navigator.clipboard.writeText) {
                          // Intentar copiar usando la API del portapapeles si está disponible
                          navigator.clipboard.writeText(value).then(() => {
                            button.innerText = 'Copiado';
                            button.disabled = true;
                            setTimeout(() => {
                              button.innerText = 'Copiar';
                              button.disabled = false;
                            }, 1500);
                          }).catch(err => {
                            console.error('Error al copiar:', err);
                          });
                        } else {
                          // Método alternativo para copiar si la API no está disponible
                          const tempInput = document.createElement('input');
                          tempInput.value = value;
                          document.body.appendChild(tempInput);
                          tempInput.select();
                          document.execCommand('copy');
                          document.body.removeChild(tempInput);

                          button.innerText = 'Copiado';
                          button.disabled = true;
                          setTimeout(() => {
                            button.innerText = 'Copiar';
                            button.disabled = false;
                          }, 1500);
                        }
                      });
                    });
                  }
                });
              } else if (response.data.response === "200" && response.data.data.isVerified && response.data.data.isDnsActive) {
                self.$refs.loader.hideLoader();
                self.obtenerDomainEmails();
                swal.fire({
                  title: '¡Configuración DNS realizada con éxito!',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-blue',
                  },
                  icon: 'success',
                });
              } else {
                self.$refs.loader.hideLoader();
                swal.fire({
                  title: 'No se pudo realizar la prueba de DNS',
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-celcom-orange',
                  },
                  icon: 'error',
                })
              }
            })
            .catch(function (error) {
              console.log("No se pudo realizar la prueba de DNS", error);
              self.$refs.loader.hideLoader();
              swal.fire({
                title: 'No se pudo realizar la prueba de DNS',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                icon: 'error',
              })
            });
    },
    //Fin de status de DomainEmail

    //Inicio de Eliminar un DomainEmail
    openModalEliminarDomain(row) {
      this.dataDomain = JSON.parse(JSON.stringify(row));
      this.modalEliminarDomain = true;
    },
    async borrarDomain() {
      this.$refs.loader.showLoader();
      let self = this;
      let user = getUser();

      let js = {
        "time_expired":horaAhora(),
        "customerDomain":encodeURIComponent(JSON.stringify(self.dataDomain)),
        "customer":encodeURIComponent(JSON.stringify(user.customer)),
      }

      var body = new URLSearchParams(); body.append('TOKEN', (encryptAES(js)));

      await deleteDomainEmail(body)
          .then(function (response) {
            if (response.data.response === "200") {
              self.responseDomainEmail = response.data.data;
              self.obtenerDomainEmails();
              self.$refs.loader.hideLoader();
              self.modalEliminarDomain = false;
            } else {
              console.log("No se pudo borrar el dominio");
              self.$refs.loader.hideLoader();
              swal.fire({
                title: 'No se pudo borrar el dominio',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-celcom-orange',
                },
                type: 'error',
                icon: 'error',
              })
            }

          })
          .catch(function (error) {
            console.log("No se pudo borrar el dominio",error);
            self.$refs.loader.hideLoader();
            swal.fire({
              title: 'No se pudo borrar el dominio',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-celcom-orange',
              },
              type: 'error',
              icon: 'error',
            })
          });
    },
    //Fin de Eliminar un DomainEmail
},
};
</script>
<style scoped>
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>