import {agreContactos} from "@/config/servicios/contactos/services";
import {decryptAES} from "@/config/servicios/campana/util";
const moment = require("moment-timezone");

export default {
    url_service: "https://automata2.celcom.cl/flex",
    login: "/api/customer/login",
    triggerTipo: "/api/flow/triggertype/4/list",
    accionDisponible: "/api/action/customer/1/list",
    listaCanales: "/api/channel/admin/1/list",
    obtenerCondicion: "/api/condition/customer/1/list"
}

export const tipoUser = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Obtener el valor de customerId
    return userDataString.adminUser;
};
export const tipoSuperUserClient = () => {
// Obtener el valor del local storage
    let encryptedToken = localStorage.getItem('user');
    const userDataString = decryptAES(encryptedToken);

// Obtener el valor de customerId
    return userDataString.customer.superUserClient;
};
export const procesarExcel = (data) => {
    const jsonDataContactos = data.map((fila) => {
        // Aquí debes acceder a cada celda de la fila y asignarla a la propiedad correspondiente
        // Esto es solo un ejemplo; debes adaptarlo según la estructura real de tus datos
        const [name, rut, tag, email, empresa, telefono, birthdate, gender, country, sourceId] = fila;

        // Devuelve un objeto con las propiedades que necesitas
        if (fila[0] !== "Nombre" && fila[0] !== undefined) {
            return {
                name,
                rut,
                email,
                empresa,
                telefono,
                tag,
                birthdate,
                gender,
                country,
                sourceId,
            };
        }
        // Devuelve null si la condición no se cumple
        return null;
    }).filter(Boolean); // Filtra los elementos nulos

    console.log(jsonDataContactos);
    const transformedData = {
        contacts: jsonDataContactos.map(contact => {
            return {
                contactId: null,
                contactName: contact.name,
                taxId: contact.rut,
                customerSegmentId: null,
                customerTag: contact.tag,
                email: contact.email,
                empresa: contact.empresa,
                msisdn: String(contact.telefono), // Convertir a cadena si es necesario
                birthDate: contact.birthdate, // Puedes necesitar una función para convertir la fecha
                gender: contact.gender, // Puedes necesitar una función para convertir el género
                country: contact.country, // Puedes necesitar una función para convertir el país
                sourceId: convertirSourceId(contact.sourceId) // Puedes necesitar una función para convertir el sourceId
            };
        })
    };

    console.log(transformedData);
    return agreContactos(transformedData)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error("Error al enviar los datos:", error);
        });
    // Puedes devolver el arreglo si lo necesitas en otro lugar
    //return jsonDataContacto;
};

function convertirSourceId(sourceId) {
    if (sourceId === "Web") {
        return 1;
    }
}
export const formatoFecha = (timestamp) => {
    if (!timestamp) {
        console.error("❌ Timestamp inválido:", timestamp);
        return "Fecha inválida";
    }

    // **Siempre convertir el timestamp a la hora de Chile**
    let fecha = moment.tz(timestamp, "America/Santiago");

    if (!fecha.isValid()) {
        console.error("❌ Fecha no válida después de moment.tz():", timestamp);
        return "Fecha inválida";
    }

    let fechaFormateada = fecha.format("YYYY-MM-DD HH:mm:ss");

    console.log(`✅ Timestamp leído: ${timestamp}`);
    console.log(`✅ Fecha mostrada en Chile (sin importar ubicación del usuario): ${fechaFormateada}`);

    return fechaFormateada;
};

export const convertirHoraChileATimestampUTC = (fechaChile) => {
    if (!fechaChile) {
        console.error("❌ Fecha ingresada inválida:", fechaChile);
        return null;
    }

    console.log(`🌍 Hora de Chile recibida: ${fechaChile}`);

    // **Asegurar que la fecha tenga segundos**
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(fechaChile)) {
        fechaChile += ":00"; // Agregar ":00" si falta la parte de los segundos
    }

    // **Convertir la fecha a timestamp usando la zona horaria de Chile**
    let fecha = moment.tz(fechaChile, "America/Santiago");

    if (!fecha.isValid()) {
        console.error("❌ Fecha no válida después de moment.tz():", fechaChile);
        return null;
    }

    let timestamp = fecha.valueOf(); // Obtener el timestamp en milisegundos

    console.log(`✅ Fecha en Chile guardada como timestamp: ${timestamp}`);

    return timestamp; // Guardamos el timestamp sin cambios
};


// Función para procesar los contactos en lotes
export const processInBatches = async (data, batchSize, processFunction) => {
    for (let i = 0; i < data.length; i += batchSize) {
        const batch = data.slice(i, i + batchSize);
        await Promise.all(batch.map(processFunction));
    }
}