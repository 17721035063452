<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="col-12 col-lg-10 mx-auto mb-4">
              <div class="card">
                <div class="card-body">
                  <div class="multisteps-form__progress">
                    <button
                      class="multisteps-form__progress-btn js-active"
                      type="button"
                      title="Datos Envío"
                      :class="activeStep >= 0 ? activeClass : ''"
                    >
                      <span>1</span>
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Vista del Mensaje"
                      :class="activeStep >= 1 ? activeClass : ''"
                    >
                      2
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Programación"
                      :class="activeStep >= 2 ? activeClass : ''"
                    >
                      3
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Vista previa del envío"
                      :class="activeStep >= 3 ? activeClass : ''"
                    >
                      4
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <form v-if="$store.state.seleccion !== 'Email'" class="multisteps-form__form">
                <!--single form panel-->
                <programada-edit-add1
                  v-model="barProgreso"
                  :list-anidadas="listAnidadas"
                  :sending-editar="sendingEditar"
                  :editando="editando"
                  :class="activeStep === 0 ? activeClass : ''"
                  @update-values="updateValues"
                />
                <!--single form panel-->
                <programada-edit-add2
                  :class="activeStep === 1 ? activeClass : ''"
                  :sender="sender"
                  :sending-editar="sendingEditar"
                  :editando="editando"
                  :seleccion-tipo-envio="seleccionTipoEnvio"
                  @update-values2="updateValues2"
                />
                <!--single form panel-->
                <programada-edit-add3
                  :sending-editar="sendingEditar"
                  :editando="editando"
                  :class="activeStep === 2 ? activeClass : ''"
                  @update-values3="updateValues3"
                />
                <!--single form panel-->
                <programada-edit-add4
                  :selected="selectTemplate"
                  :sendingsms="sendingsms"
                  :sendingwhatsapp="sendingWhatsapp"
                  :class="activeStep === 3 ? activeClass : ''"
                />
              </form>
              <form v-else-if="$store.state.seleccion === 'Email'" class="multisteps-form__form">
                <!--single form panel-->
                <programada-email-add1
                    v-model="barProgreso"
                    :list-anidadas="listAnidadas"
                    :sending-editar="sendingEditar"
                    :editando="editando"
                    :class="activeStep === 0 ? activeClass : ''"
                    @update-values="updateValues"
                />
                <!--single form panel-->
                <programada-email-add2
                    :class="activeStep === 1 ? activeClass : ''"
                    :sender="sender"
                    :sending-editar="sendingEditar"
                    :editando="editando"
                    @update-values2-email="updateValues2Email"
                />
                <!--single form panel-->
                <programada-email-add3
                    :sending-editar="sendingEditar"
                    :editando="editando"
                    :class="activeStep === 2 ? activeClass : ''"
                    @update-values3="updateValues3"
                />
                <!--single form panel-->
                <programada-email-add4
                    :sendingemail="sendingEmail"
                    :class="activeStep === 3 ? activeClass : ''"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader ref="loader" />
  </div>
</template>

<script>
import ProgramadaEditAdd1 from "@/views/envios/components/ProgramadaEditAdd1.vue";
import ProgramadaEditAdd2 from "@/views/envios/components/PragramadaEditAdd2.vue";
import ProgramadaEditAdd3 from "@/views/envios/components/PragramadaEditAdd3.vue";
import ProgramadaEditAdd4 from "@/views/envios/components/PragramadaEditAdd4.vue";
import Loader from "@/components/Modal/Loader.vue";
import ProgramadaEmailAdd1 from "@/views/envios/components/email/ProgramadaEditAdd1.vue";
import ProgramadaEmailAdd2 from "@/views/envios/components/email/PragramadaEditAdd2.vue";
import ProgramadaEmailAdd3 from "@/views/envios/components/email/PragramadaEditAdd3.vue";
import ProgramadaEmailAdd4 from "@/views/envios/components/email/PragramadaEditAdd4.vue";
import {
  encryptAES,
  horaAhora,
  getUser,
  getUserRealTime,
  getUserSub,
} from "@/config/servicios/campana/util";
import {
  smsGetProgramado,
  deleteProgramado,
  sending_sms_send,
  sending_sms_create_edit,
  sending_whatsapp_create_edit,
  sending_whatsapp_send,
  sending_email_create_edit,
  sending_email_send,
  sending_sms_sendTestCampaign, sending_sms_sendTestWhatsapp, sending_sms_sendTestEmail,
} from "@/config/servicios/campana/envios/servicesProgramados";
import moment from "moment";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "ProgramadaEditAdd",
  components: {
    ProgramadaEditAdd1,
    ProgramadaEditAdd2,
    ProgramadaEditAdd3,
    ProgramadaEditAdd4,
    Loader,
    ProgramadaEmailAdd1,
    ProgramadaEmailAdd2,
    ProgramadaEmailAdd3,
    ProgramadaEmailAdd4,
  },
  data() {
    return {
      idSeguimiento: [],
      seleccionTipoEnvio: "",
      short_url: "",
      data: null,
      contactLista: [],
      sender: "",
      campaingName: "",
      menssageContact: "",
      selectDraft: [],
      selectTemplate: [],
      dateProgramgContact: "",
      sendingsms: {},
      sendingWhatsapp: {},
      sendingEmail: {},
      sendingEditar: {},
      editando: false,
      menssageEmail: "",
      subjetEmail: "",

      seleccion: "",
      barProgreso: 0,
      contadorContactImportados: 0,
      tipoMensajeValue: false,
      nameContactList: "",
      checkEnvioProgramado: true,
      previewSend: [
        {
          phoneCode: "569",
          phone: "12345678",
          message: "Mensaje de prueba",
        },
      ],
      previewDraft: [],
      contadorMultipleContactList: [],
      contadorMultipleContactListCurrent: 0,
      listAnidadas: [],
      checkImportList: false,
      mostrarAlertEnvio: false,
      totalCreditosUsar: 0,
      selectOptionsListDraftbyContactGroup: [],
      selectOptionsListContact: [],
      selectOptionsListDraft: [],
      selectOptionsListLanding: [],
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      contadorListImportadas: 0,
      formSteps: 3,
      fechaProgramada: null,
      validForm: {
        estado: false,
        field: "",
        campaingName: "",
        CodePhoneContact: getUser().customer.country.phoneCode,
        PhoneContact: "",
        listContact: [],
        dateProgramgContact: "",
        menssageContact: "",
        selectDraft: [],
        selectLanding: [],
        StepPreview: false,
        dateSend: "",
        sendingsms: "",
        contacts: [],
        childrenId: null,
        sendingTotal: 0,
        sendingsmsId: null,
        sendingUDH: [],
        position: 0,
      },
      selectedTiempo: null,
      selectedTiempoEmail: null,
    };
  },
  computed: {
    isActive() {
      const estadoCanales = this.$store.state.estado_canales || [];
      const canalesActivos = {
        whatsapp: false,
        email: false,
        sms: false
      };

      estadoCanales.forEach(canal => {
        if (canal.channel.channel_name === "WHATSAPP" && canal.channel.active && canal.active) {
          canalesActivos.whatsapp = true;
        } else if (canal.channel.channel_name === "EMAIL" && canal.channel.active && canal.active) {
          canalesActivos.email = true;
        } else if (canal.channel.channel_name === "SMS" && canal.channel.active && canal.active) {
          canalesActivos.sms = true;
        }
      });

      return canalesActivos;
    }
  },
  watch: {
    "validForm.selectDraft": {
      handler() {
        let self = this;
        self.previewDraft = [];
        //BUSCA NOMBRE DE DRAFT SELECCIONADA
        this.selectOptionsListDraftbyContactGroup.forEach(function (key) {
          self.validForm.selectDraft.forEach(function (key2) {
            if (key.draftId == key2) {
              self.previewDraft.push(key);
            }
          });
        });
      },
      immediate: true,
      deep: true,
    },

    "validForm.selectLanding": {
      handler(newVal) {
        let self = this;
        self.validForm.menssageContact += " " + newVal;
      },
      immediate: true,
      deep: true,
    },

    "validForm.listContact": {
      handler(newVal) {
        let self = this;
        if (!self.checkImportList) {
          self.listAnidadas = [];
          self.contadorMultipleContactList = [];
          self.contadorMultipleContactListCurrent = 0;
          self.selectOptionsListDraftbyContactGroup = [];
          self.totalCreditosUsar = 0;
          if (newVal.length >= 1) {
            newVal.forEach(function (key) {
              self.selectOptionsListContact.forEach(function (key2) {
                if (key2.contactGroupId == key) {
                  self.selectOptionsListDraft.forEach(function (draft) {
                    if (
                      draft.contactGroup.contactGroupId ==
                        key2.contactGroupId ||
                      draft.master
                    ) {
                      if (
                        self.selectOptionsListDraftbyContactGroup.indexOf(
                          draft
                        ) == -1
                      ) {
                        self.selectOptionsListDraftbyContactGroup.push(draft);
                      }
                    }
                  });

                  //self.contadorListImportadas+=1;
                  self.listAnidadas.push({
                    id: key2.contactGroupId,
                    nombre: key2.name,
                    contactos: key2.validos + key2.invalidos,
                  });

                  self.totalCreditosUsar += key2.validos + key2.invalidos;

                  self.contadorMultipleContactList.push(key2.contactGroupId);
                  self.contadorMultipleContactListCurrent++;
                }
              });
            });
          }
        } else {
          if (newVal.length >= 1) {
            newVal.forEach(function (key) {
              self.selectOptionsListContact.forEach(function (key2) {
                if (key2.contactGroupId == key) {
                  self.selectOptionsListDraft.forEach(function (draft) {
                    if (
                      draft.contactGroup.contactGroupId ==
                        key2.contactGroupId ||
                      draft.master
                    ) {
                      if (
                        self.selectOptionsListDraftbyContactGroup.indexOf(
                          draft
                        ) == -1
                      ) {
                        self.selectOptionsListDraftbyContactGroup.push(draft);
                      }
                    }
                  });
                }
              });
            });
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    //this.loadView();
  },
  async mounted() {
    if (typeof this.$route.query.sending_sms !== "undefined") {
      this.sendingEditar = this.$store.state.programado;

      this.obtenerTipo();
      switch (this.sendingEditar.channelId) {
        case 1:
          this.$store.dispatch('guardarSeleccion', 'SMS');
          break;
        case 2:
          this.$store.dispatch('guardarSeleccion', 'Whatsapp');
          break;
        case 3:
          this.$store.dispatch('guardarSeleccion', 'Email');
          break;
      }
      console.log(JSON.stringify(this.sendingEditar))
      this.editando = true;

      Swal.fire({
        title: "Estás editando un envío programado",
        icon: "info",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-celcom-blue btn-fill",
        }
      })
    } else {
      this.viaProgramada();
    }
    await this.$store.getters.plantillasSmsGet();
    await this.$store.getters.list_template_whatsapp();
    await this.$store.getters.plantillasEmailGet();
    await this.$store.getters.available_short_urls();
  },
  methods: {
    obtenerTipo(){
      this.sendingEditar.filterstr = JSON.parse(decodeURIComponent(this.sendingEditar.filterstr));
      this.sendingEditar.trackId = JSON.parse(decodeURIComponent(this.sendingEditar.trackId));
    },
    async viaProgramada() {
      await Swal.fire({
        title: "Seleccione Canal de Envio",
        icon: "question",
        html: `<p style="font-size: small; color: red;">La selección de un canal de envío es obligatoria</p>
   <div class="d-flex p-3 justify-content-around flex-column flex-md-row">
     <button id="btn-whatsapp" class="col-md btn btn-success" tabindex="-1" ${!this.isActive.whatsapp ? 'disabled' : ''}><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#fff"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" /><path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" /></svg>Whatsapp</button>
     <button id="btn-email" class="col-md mx-md-2 btn btn-info" tabindex="-1" ${!this.isActive.email ? 'disabled' : ''}><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#fff"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>Email</button>
     <button id="btn-sms" class="col-md btn btn-secondary" tabindex="-1" ${!this.isActive.sms ? 'disabled' : ''}><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#fff"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 9h8" /><path d="M8 13h6" /><path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" /></svg>SMS</button>
   </div>
   <button id="btn-cerrar" class="swal2-close" tabindex="-1" aria-label="Close this dialog" style="position: absolute; top: 10px; right: 10px; font-size: 1.5em; background: none; border: none;">&times;</button>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        didRender: () => {
          // Botón Whatsapp
          const btnWhatsapp = document.getElementById('btn-whatsapp');
          if (this.isActive.whatsapp) {
            btnWhatsapp.addEventListener('click', () => {
              this.$store.dispatch('guardarSeleccion', 'Whatsapp');
              Swal.close();
            });
          }

          // Botón Email
          const btnEmail = document.getElementById('btn-email');
          if (this.isActive.email) {
            btnEmail.addEventListener('click', () => {
              this.$store.dispatch('guardarSeleccion', 'Email');
              Swal.close();
            });
          }

          // Botón SMS
          const btnSms = document.getElementById('btn-sms');
          if (this.isActive.sms) {
            btnSms.addEventListener('click', () => {
              this.$store.dispatch('guardarSeleccion', 'SMS');
              Swal.close();
            });
          }

          // Botón Cerrar con redirección
          document.getElementById('btn-cerrar').addEventListener('click', () => {
            this.$router.push({
              name: "Dashboard"
            });
            Swal.close();
          });
        }
      });
    },
    updateValues(newValues) {
      this.seleccionTipoEnvio = newValues.seleccionTipoEnvio;
      this.campaingName = newValues.campaingName;
      this.sender = newValues.sender;
      this.data = newValues.data;
      this.contactLista = newValues.contactLista;
    },
    updateValues2(newValues) {
      if (newValues.message !== undefined) {
        this.menssageContact = newValues.message;
        this.selectDraft = newValues.selectDraft;
        this.selectTemplate = newValues.selectTemplate;
        this.short_url = newValues.short_url;
        this.idSeguimiento = newValues.idSeguimiento;
        this.selectedTiempo = newValues.cantidadSmsTiempo;
      }
    },
    updateValues3(newValues) {
      if (newValues.fecha !== undefined) {
        this.dateProgramgContact = newValues.fecha;
      }
    },
    updateValues2Email(newValues) {
      if (newValues.message !== undefined) {
        this.menssageEmail = newValues.message;
        this.subjetEmail = newValues.selectDraft[0] ? newValues.selectDraft[0].subject : newValues.subjet;
        this.selectDraft = newValues.selectDraft;
        this.selectTemplate = newValues.selectTemplate;
        this.idSeguimiento = newValues.idSeguimiento;
        this.selectedTiempoEmail = newValues.cantidadSmsTiempo;
      }
    },
    /**
     * CARGAR INFORMACION DEL SENDING PARA EDITAR Y REENVIAR
     */
    async getSending() {
      if (typeof this.$route.query.sending_sms !== "undefined") {
        let self = this;
        let user = getUserSub() != null ? getUserSub() : getUser();

        let js = {
          time_expired: horaAhora(),
          sending_sms: this.$route.query.sending_sms,
          customer: encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));
        await smsGetProgramado(body)
          .then(function (response) {
            var ahoraCheckbox = document.getElementById("ahora");
            if (response.data.response === "200") {
              self.sendingInfoEdit = response.data.data;

              self.validForm.SendingSmsId = response.data.data.sendingSmsId;
              self.validForm.campaingName = response.data.data.name;
              self.validForm.CodePhoneContact = response.data.data.phoneCode;
              self.validForm.PhoneContact = response.data.data.phone;
              self.validForm.menssageContact = response.data.data.message;

              if (
                response.data.data.receiverId !== null &&
                response.data.data.receiverId !== 0 &&
                response.data.data.receiverId !== ""
              ) {
                //self.validForm.listContact=[];
                self.validForm.listContact.push(response.data.data.receiverId);
                //self.radios.radio1='unchecked';
                ahoraCheckbox.checked = false;
              }

              if (
                response.data.data.draftId !== null &&
                response.data.data.draftId !== 0 &&
                response.data.data.draftId !== ""
              ) {
                //self.validForm.selectDraft=[]
                //document.getElementById("tipo-mensaje").checked = false;
                //self.radios.redactar='unchecked';
                self.previewDraft = [];
                self.validForm.selectDraft[0] = response.data.data.draftId;

                //BUSCA NOMBRE DE DRAFT SELECCIONADA
                self.selectOptionsListDraft.forEach(function (key) {
                  if (key.draftId === response.data.data.draftId) {
                    self.previewDraft.push(key);
                  }
                });
              }

              if (response.data.data.calendarType === 1) {
                ahoraCheckbox.checked = true;
              } else {
                ahoraCheckbox.checked = false;
              }

              self.validForm.dateSend = moment(
                response.data.data.calendar
              ).format("YYYY-MM-DD HH:mm:ss");
              self.validForm.dateProgramgContact = moment(
                response.data.data.calendar
              ).format("YYYY-MM-DD HH:mm:ss");
            } else {
              /* swal({
                    title: "Error no se pudo carga la informacion del envio",
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-danger btn-fill'
                  })*/
              console.log("Error no se pudo carga la informacion del envio");
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
                  title: "Error no se pudo carga la informacion del envio",
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-danger btn-fill',
                  icon: 'error'
                })
          });
      }
    },
    async realizarTest(dataPruebas) {
      this.$refs.loader.showLoader();
      let self = this;
      self.totalCreditosUsar = self.sendingsms.campaignCost;

      let user = await getUserRealTime();

      if (typeof this.$route.query.sending_sms !== "undefined") {
        await self.borrarSending(self.sendingsms);
      }

      if (!document.getElementById("ahora").checked) {
        var currentDate2 = new Date();
        currentDate2 = new Date(this.dateProgramgContact).getTime();
      }

      if (!self.checkEnvioProgramado) {
        return false;
      }

      var currentDate = new Date();
      currentDate = new Date(currentDate).getTime();
      moment.locale("es");
      self.validForm.dateSend = document.getElementById("ahora").checked
          ? moment(currentDate).format("LLL")
          : moment(currentDate2).format("LLL");

      self.sendingsms.calendar = document.getElementById("ahora").checked
          ? currentDate
          : currentDate2;

      let js = {
        customer_user: encodeURIComponent(JSON.stringify(user)),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        sending_sms: encodeURIComponent(JSON.stringify(this.sendingsms)),
        application_id: "1",
        sender: this.sender
            ? encodeURIComponent(JSON.stringify(this.sender))
            : encodeURIComponent(JSON.stringify({})),
        time_expired: horaAhora(),
        contacts: encodeURIComponent(
            JSON.stringify(
                Object.keys(this.contactLista).length !== 0 ? this.contactLista : []
            )
        ), // si es lista []
        filter: encodeURIComponent(
            JSON.stringify(
                dataPruebas
            )
        ),
        short_url: this.short_url
            ? encodeURIComponent(JSON.stringify(this.short_url))
            : encodeURIComponent(JSON.stringify({})),
        idSeguimiento: encodeURIComponent(JSON.stringify(this.idSeguimiento)),
      };
      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await sending_sms_sendTestCampaign(body)
          .then(function (response) {
            self.validForm.StepPreview = false;
            if (response.data.response === "200") {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Prueba enviada con exito",
                icon: "success",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-celcom-orange btn-fill",
                }
              });
              return true;
            }else {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Error al procesar mensaje",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-celcom-orange btn-fill",
                }
              });
            }
          })
          .catch(function (error) {
            console.log("No se encontró preview", error);
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error al procesar mensaje",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange btn-fill",
              }
            });
          });
    },
    async realizarTestWhatsapp(dataPruebas) {
      this.$refs.loader.showLoader();
      let self = this;
      self.totalCreditosUsar = self.sendingWhatsapp.campaignCost;

      let user = await getUserRealTime();

      if (typeof this.$route.query.sendingWhatsapp !== "undefined") {
        await self.borrarSending(self.sendingWhatsapp);
      }

      if (!document.getElementById("ahora").checked) {
        var currentDate2 = new Date();
        currentDate2 = new Date(this.dateProgramgContact).getTime();
      }

      if (!self.checkEnvioProgramado) {
        return false;
      }

      var currentDate = new Date();
      currentDate = new Date(currentDate).getTime();
      moment.locale("es");
      self.validForm.dateSend = document.getElementById("ahora").checked
          ? moment(currentDate).format("LLL")
          : moment(currentDate2).format("LLL");

      self.sendingWhatsapp.calendar = document.getElementById("ahora").checked
          ? currentDate
          : currentDate2;

      let js = {
        customer_user: encodeURIComponent(JSON.stringify(user)),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        sending_sms: encodeURIComponent(JSON.stringify(this.sendingWhatsapp)),
        application_id: "1",
        sender: this.sender
            ? encodeURIComponent(JSON.stringify(this.sender))
            : encodeURIComponent(JSON.stringify({})),
        time_expired: horaAhora(),
        contacts: encodeURIComponent(
            JSON.stringify(
                Object.keys(this.contactLista).length !== 0 ? this.contactLista : []
            )
        ), // si es lista []
        filter: encodeURIComponent(
            JSON.stringify(
                dataPruebas
            )
        ),
        short_url: this.short_url
            ? encodeURIComponent(JSON.stringify(this.short_url))
            : encodeURIComponent(JSON.stringify({})),
        idSeguimiento: encodeURIComponent(JSON.stringify(this.idSeguimiento)),
      };
      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await sending_sms_sendTestWhatsapp(body)
          .then(function (response) {
            self.validForm.StepPreview = false;
            if (response.data.response === "200") {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Prueba enviada con exito",
                icon: "success",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-celcom-orange btn-fill",
                }
              });
              return true;
            }else {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Error al procesar mensaje",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-celcom-orange btn-fill",
                }
              });
            }
          })
          .catch(function (error) {
            console.log("No se encontró preview", error);
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error al procesar mensaje",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange btn-fill",
              }
            });
          });
    },
    async realizarTestEmail(dataPruebas) {
      this.$refs.loader.showLoader();
      let self = this;
      self.totalCreditosUsar = self.sendingEmail.campaignCost;

      let user = await getUserRealTime();

      if (typeof this.$route.query.sending_sms !== "undefined") {
        await self.borrarSending(self.sendingEmail);
      }

      if (!document.getElementById("ahoraEmail").checked) {
        var currentDate2 = new Date();
        currentDate2 = new Date(this.dateProgramgContact).getTime();
      }

      if (!self.checkEnvioProgramado) {
        return false;
      }

      var currentDate = new Date();
      currentDate = new Date(currentDate).getTime();
      moment.locale("es");
      self.validForm.dateSend = document.getElementById("ahoraEmail").checked
          ? moment(currentDate).format("LLL")
          : moment(currentDate2).format("LLL");

      self.sendingEmail.calendar = document.getElementById("ahoraEmail").checked
          ? currentDate
          : currentDate2;

      let js = {
        customer_user: encodeURIComponent(JSON.stringify(user)),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        sending_sms: encodeURIComponent(JSON.stringify(this.sendingEmail)),
        application_id: "1",
        sender: this.sender
            ? encodeURIComponent(JSON.stringify(this.sender))
            : encodeURIComponent(JSON.stringify({})),
        time_expired: horaAhora(),
        contacts: encodeURIComponent(
            JSON.stringify(
                Object.keys(this.contactLista).length !== 0 ? this.contactLista : []
            )
        ), // si es lista []
        filter: encodeURIComponent(
            JSON.stringify(
                dataPruebas
            )
        ),
        short_url: this.short_url
            ? encodeURIComponent(JSON.stringify(this.short_url))
            : encodeURIComponent(JSON.stringify({})),
        idSeguimiento: encodeURIComponent(JSON.stringify(this.idSeguimiento)),
      };
      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await sending_sms_sendTestEmail(body)
          .then(function (response) {
            self.validForm.StepPreview = false;
            if (response.data.response === "200") {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Prueba enviada con exito",
                icon: "success",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-celcom-orange btn-fill",
                }
              });
              return true;
            }else {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Error al procesar mensaje",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-celcom-orange btn-fill",
                }
              });
            }
          })
          .catch(function (error) {
            console.log("No se encontró preview", error);
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error al procesar mensaje",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange btn-fill",
              }
            });
          });
    },
    async envioSms() {
      this.$refs.loader.showLoader();

      let self = this;
      let user = await getUserRealTime();

      self.mostrarAlertEnvio = false;

      var currentDate = new Date();
      currentDate = new Date(currentDate).getTime();

      // if (!document.getElementById("ahora").checked) {
      //   var currentDate2 = new Date();
      //   currentDate2 = new Date(this.dateProgramgContact).getTime();
      // }

      self.totalCreditosUsar = 1;
      this.sendingsms = {
        sendingSmsId: this.sendingEditar.sendingSmsId ? this.sendingEditar.sendingSmsId : null,
        customerId: user.customer.customerId,
        name: this.campaingName, //nombre campanna
        receiverType: this.seleccionTipoEnvio === "individual" ? 1 : 2, //1 para individual y cuando es lista 2
        receiverId: null,
        phoneCode: null,
        phone: null,
        draftId: null,
        messageType: this.menssageContact === "" ? 2 : 1, // si tiene plantilla(draft) es 2 sino 1
        message:
          this.menssageContact === ""
            ? this.selectDraft[0].message
            : this.menssageContact, // mensagge a enviar y solo puede ser una plantilla
        sendSending: "PREVIEW",
        draftFlexId: this.selectDraft[0] ? this.selectDraft[0].draftId : null, //id de la plantilla
        calendarType:
          document.getElementById("programar").checked ? 2 : 1,
        calendar: this.dateProgramgContact,
        createdby: user.customerUserId,
        created: currentDate,
        aborted: false,
        typeApi: null,
        receiverIdTotal: 1,
        receiverIdInit: 1,
        receiverIdEnd: 1,
        receiverIdPosition: 0,
        sendingPosition: 1,
        abortedby: null,
        abortedDate: null,
        sendingSmsDetailList: [],
        sendingStatus: "1",
        sendingTotal: null,
        childrenId: null,
        channelId: 1,
        whatsappSenderId: null,
        sendingSmsConfiguration: (this.selectedTiempo.smsNumber !== null && this.selectedTiempo.smsNumber !== 0 && this.selectedTiempo.smsNumber !== "")
            ? JSON.stringify(this.selectedTiempo)
            : JSON.stringify({
              smsNumber: null,
              timeUnit: null
            }),
      };

      let js = {
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        sending_sms: encodeURIComponent(JSON.stringify(this.sendingsms)),
        application_id: "1",
        sender: this.sender
          ? encodeURIComponent(JSON.stringify(this.sender))
          : encodeURIComponent(JSON.stringify({})),
        time_expired: horaAhora(),
        contacts: encodeURIComponent(
          JSON.stringify(
            Object.keys(this.contactLista).length !== 0 ? this.contactLista : []
          )
        ), // si es lista []
        filter: encodeURIComponent(
          JSON.stringify(
            Object.keys(this.contactLista).length !== 0 ? {} : this.data
          )
        ),
        short_url: this.short_url
          ? encodeURIComponent(JSON.stringify(this.short_url))
          : encodeURIComponent(JSON.stringify({})),
        idSeguimiento: encodeURIComponent(JSON.stringify(this.idSeguimiento)),
      };
      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await sending_sms_create_edit(body)
        .then(function (response) {  
          self.validForm.StepPreview = false;
          if (response.data.response === "200") {
            self.sendingsms = response.data.data;
            self.$refs.loader.hideLoader();
            self.tipoMensajeValue = self.seleccionTipoEnvio;
            self.nextStep();
            return true;
          } else if (response.data.message === "CREDIT ERROR") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error creditos insuficientes",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill",
              }
            });
          } else if (response.data.data.sendingStatus === "2") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Horario Invalido. Contactar a su ejecutivo",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill",
              }
            });
          } else if (response.data.message === "PRIMER ENVIO") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title:
                "Debe esperar que se apruebe su cuenta para poder hacer envios",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange btn-fill",
              },
              icon: 'warning'
            });
          } else if (response.data.message === "ERROR PALABRA BLOQUEADA") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error el texto del mensaje contiene palabras bloqueadas",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange btn-fill",
              }
            });
          } else {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error al procesar mensaje",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange btn-fill",
              }
            });
          }
        })
        .catch(function (error) {
          console.log("No se encontró preview", error);
          self.$refs.loader.hideLoader();
          Swal.fire({
            title: "Error al procesar mensdasdaje",
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-celcom-orange btn-fill",
            }
          });
        });
    },
    async envioWhatsapp() {
      this.$refs.loader.showLoader();
      let self = this;
      let user = await getUserRealTime();

      self.mostrarAlertEnvio = false;

      var currentDate = new Date();
      currentDate = new Date(currentDate).getTime();

      // if (!document.getElementById("ahora").checked) {
      //   var currentDate2 = new Date();
      //   currentDate2 = new Date(this.dateProgramgContact).getTime();
      // }
      self.totalCreditosUsar = 1;
      this.sendingWhatsapp = {
        sendingSmsId: this.sendingEditar.sendingSmsId ? this.sendingEditar.sendingSmsId : null,
        customerId: user.customer.customerId,
        name: this.campaingName, //nombre campanna
        receiverType: this.seleccionTipoEnvio === "individual" ? 1 : 2, //1 para individual y cuando es lista 2
        receiverId: null,
        phoneCode: null,
        phone: null,
        draftId: null,
        messageType: 1,
        message:
          this.menssageContact === ""
            ? this.selectTemplate[0].body
            : this.menssageContact, // mensagge a enviar y solo puede ser una plantilla
        sendSending: "PREVIEW",
        draftFlexId: this.selectTemplate[0] ? this.selectTemplate[0].whatsappSenderId : null, //id de la plantilla
        calendarType:
          document.getElementById("programar").checked ? 2 : 1,
        calendar: this.dateProgramgContact,
        createdby: user.customerUserId,
        created: currentDate,
        aborted: false,
        typeApi: null,
        receiverIdTotal: 1,
        receiverIdInit: 1,
        receiverIdEnd: 1,
        receiverIdPosition: 0,
        sendingPosition: 1,
        abortedby: null,
        abortedDate: null,
        sendingSmsDetailList: [],
        sendingStatus: "1",
        sendingTotal: null,
        childrenId: null,
        channelId: 2,
        whatsappSenderId: this.selectTemplate[0]
          ? this.selectTemplate[0].whatsappSenderId
          : null,
        sendingSmsConfiguration: (this.selectedTiempo.smsNumber !== null && this.selectedTiempo.smsNumber !== 0 && this.selectedTiempo.smsNumber !== "")
            ? JSON.stringify(this.selectedTiempo)
            : JSON.stringify({
              smsNumber: null,
              timeUnit: null
            })
      };
      let js = {
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        sending_sms: encodeURIComponent(JSON.stringify(this.sendingWhatsapp)),
        application_id: "1",
        sender: encodeURIComponent(JSON.stringify({})),
        time_expired: horaAhora(),
        contacts: encodeURIComponent(
          JSON.stringify(
            Object.keys(this.contactLista).length !== 0 ? this.contactLista : []
          )
        ), // si es lista []
        filter: encodeURIComponent(
          JSON.stringify(
            Object.keys(this.contactLista).length !== 0 ? {} : this.data
          )
        ),
        short_url: encodeURIComponent(JSON.stringify({})),
        idSeguimiento: encodeURIComponent(JSON.stringify(this.idSeguimiento)),
      };
      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await sending_whatsapp_create_edit(body)
        .then(function (response) {
          console.log(response);

          self.validForm.StepPreview = false;

          if (response.data.response === "200") {
            self.sendingWhatsapp = response.data.data;
            self.$refs.loader.hideLoader();
            self.tipoMensajeValue = self.seleccionTipoEnvio;
            self.nextStep();
            return true;
          } else if (response.data.message === "CREDIT ERROR") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error creditos insuficientes",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill",
              }
            });
          } else if (response.data.data.sendingStatus === "2") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Horario Invalido. Contactar a su ejecutivo",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill",
              }
            });
          } else if (response.data.message === "PRIMER ENVIO") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title:
                "Debe esperar que se apruebe su cuenta para poder hacer envios",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill",
              }
            });
          } else if (response.data.message === "ERROR PALABRA BLOQUEADA") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error el texto del mensaje contiene palabras bloqueadas",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill",
              }
            });
          } else {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error al procesar mensaje",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange btn-fill",
              }
            });
          }
        })
        .catch(function (error) {
          console.log("No se encontró preview", error);
          self.$refs.loader.hideLoader();
          Swal.fire({
            title: "Error al procesar mensaje",
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-celcom-orange btn-fill",
            }
          });
        });
    },
    async envioEmail() {
      this.$refs.loader.showLoader();
      let self = this;
      let user = await getUserRealTime();

      self.mostrarAlertEnvio = false;

      var currentDate = new Date();
      currentDate = new Date(currentDate).getTime();

      // if (!document.getElementById("ahoraEmail").checked) {
      //   var currentDate2 = new Date();
      //   currentDate2 = new Date(this.dateProgramgContact).getTime();
      // }

      self.totalCreditosUsar = 1;
      this.sendingEmail = {
        sendingSmsId: this.sendingEditar.sendingSmsId ? this.sendingEditar.sendingSmsId : null,
        customerId: user.customer.customerId,
        name: this.campaingName, //nombre campanna
        receiverType: this.seleccionTipoEnvio === "individual" ? 1 : 2, //1 para individual y cuando es lista 2
        receiverId: null,
        phoneCode: null,
        phone: null,
        draftId: null,
        messageType: this.selectDraft[0] ? 2 : 1, // si tiene plantilla(draft) es 2 sino 1
        message:
            !this.menssageEmail.includes('project')
                ? this.selectDraft[0].message
                : this.menssageEmail, // mensagge a enviar y solo puede ser una plantilla
        sendSending: "PREVIEW",
        draftFlexId: this.selectDraft[0] ? this.selectDraft[0].draftId : null, //id de la plantilla
        calendarType:
            document.getElementById("programarEmail").checked ? 2 : 1,
        calendar: this.dateProgramgContact,
        createdby: user.customerUserId,
        created: currentDate,
        aborted: false,
        typeApi: null,
        receiverIdTotal: 1,
        receiverIdInit: 1,
        receiverIdEnd: 1,
        receiverIdPosition: 0,
        sendingPosition: 1,
        abortedby: null,
        abortedDate: null,
        sendingSmsDetailList: [],
        sendingStatus: "1",
        sendingTotal: null,
        childrenId: null,
        channelId: 3,
        subject: this.subjetEmail,
        sendingSmsConfiguration: (this.selectedTiempoEmail.smsNumber !== null && this.selectedTiempoEmail.smsNumber !== 0 && this.selectedTiempoEmail.smsNumber !== "")
            ? JSON.stringify(this.selectedTiempoEmail)
            : JSON.stringify({
                  smsNumber: null,
                  timeUnit: null
              })
      };
      
      let js = {
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        sending_sms: encodeURIComponent(JSON.stringify(this.sendingEmail)),
        application_id: "1",
        sender: this.sender
            ? encodeURIComponent(JSON.stringify(this.sender))
            : encodeURIComponent(JSON.stringify({})),
        time_expired: horaAhora(),
        contacts: encodeURIComponent(
            JSON.stringify(
                Object.keys(this.contactLista).length !== 0 ? this.contactLista : []
            )
        ), // si es lista []
        filter: encodeURIComponent(
            JSON.stringify(
                Object.keys(this.contactLista).length !== 0 ? {} : this.data
            )
        ),
        short_url: encodeURIComponent(JSON.stringify({})),
        idSeguimiento: encodeURIComponent(JSON.stringify(this.idSeguimiento)),
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await sending_email_create_edit(body)
          .then(function (response) {
            self.validForm.StepPreview = false;

            if (response.data.response === "200") {
              self.sendingEmail = response.data.data;
              self.$refs.loader.hideLoader();
              self.tipoMensajeValue = self.seleccionTipoEnvio;
              self.nextStep();
              return true;
            } else if (response.data.message === "CREDIT ERROR") {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Error creditos insuficientes",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-danger btn-fill",
                }
              });
            } else if (response.data.data.sendingStatus === "2") {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Horario Invalido. Contactar a su ejecutivo",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-danger btn-fill",
                }
              });
            } else if (response.data.message === "PRIMER ENVIO") {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title:
                    "Debe esperar que se apruebe su cuenta para poder hacer envios",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-danger btn-fill",
                },
                icon: 'warning'
              });
            } else if (response.data.message === "ERROR PALABRA BLOQUEADA") {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Error el texto del mensaje contiene palabras bloqueadas",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-danger btn-fill",
                }
              });
            } else {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Error al procesar mensaje",
                icon: "error",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-celcom-orange btn-fill",
                }
              });
            }
          })
          .catch(function (error) {
            console.log("No se encontró preview", error);
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error al procesar mensaje",
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange btn-fill",
              }
            });
          });
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    async borrarSending(sendingsms) {
      let self = this;
      let user = getUserSub() != null ? getUserSub() : getUser();

      let js = {
        time_expired: horaAhora(),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        customer_user: encodeURIComponent(JSON.stringify(user)),
        sending_sms: encodeURIComponent(JSON.stringify(sendingsms)),
        application_id: 1,
      };

      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await deleteProgramado(body).then(function (response) {
        console.log(response);

        if (response.data.response === "200") {
          console.log(response.data.data);
        } else {
          self.checkEnvioProgramado = false;

          Swal.fire({
            title: "Error borrado envio programado ",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger btn-fill",
            },
            icon: 'error'
          });
        }
      });
    },
    /**
     * Metodo de Envio individual
     */
    async envioServices() {
      this.$refs.loader.showLoader();
      let self = this;
      self.totalCreditosUsar = self.sendingsms.campaignCost;
      if (!self.mostrarAlertEnvio) {
        var msn = !document.getElementById("ahora").checked
          ? "programar"
          : "enviar";
        Swal.fire({
          title: "¿Estás seguro de " + msn + " esta campaña?",
          text:
            "Se van a utilizar $" +
            self.totalCreditosUsar +
            " de tu cuenta",
          type: "info",
          showCancelButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-celcom-blue me-3",
            cancelButton: "btn btn-dark",
          },
          confirmButtonText: "Si, " + msn,
          cancelButtonText: "Cancelar",
          closeOnConfirm: false,
          closeOnCancel: false,
        }).then(function (isconfirm) {
          if (isconfirm.value) {
            self.mostrarAlertEnvio = true;
            self.envioServices();
          }
        });
        self.$refs.loader.hideLoader();
        return false;
      }
      let user = await getUserRealTime();

      if (typeof this.$route.query.sending_sms !== "undefined") {
        await self.borrarSending(self.sendingsms);
      }

      if (!document.getElementById("ahora").checked) {
        var currentDate2 = new Date();
        currentDate2 = new Date(this.dateProgramgContact).getTime();
      }

      if (!self.checkEnvioProgramado) {
        return false;
      }

      var currentDate = new Date();
      currentDate = new Date(currentDate).getTime();
      moment.locale("es");
      self.validForm.dateSend = document.getElementById("ahora").checked
        ? moment(currentDate).format("LLL")
        : moment(currentDate2).format("LLL");

      self.sendingsms.calendar = document.getElementById("ahora").checked
        ? currentDate
        : currentDate2;

      let js = {
        customer_user: encodeURIComponent(JSON.stringify(user)),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        sending_sms: encodeURIComponent(JSON.stringify(this.sendingsms)),
        application_id: "1",
        sender: this.sender
          ? encodeURIComponent(JSON.stringify(this.sender))
          : encodeURIComponent(JSON.stringify({})),
        time_expired: horaAhora(),
        contacts: encodeURIComponent(
          JSON.stringify(
            Object.keys(this.contactLista).length !== 0 ? this.contactLista : []
          )
        ), // si es lista []
        filter: encodeURIComponent(
          JSON.stringify(
            Object.keys(this.contactLista).length !== 0 ? {} : this.data
          )
        ),
        short_url: this.short_url
          ? encodeURIComponent(JSON.stringify(this.short_url))
          : encodeURIComponent(JSON.stringify({})),
        idSeguimiento: encodeURIComponent(JSON.stringify(this.idSeguimiento)),
      };
      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await sending_sms_send(body)
        .then(function (response) {
          console.log(response);

          if (response.data.response === "200") {
            self.$refs.loader.hideLoader();

            Swal.fire({
              title: !document.getElementById("ahora").checked
                ? "Campaña programada con exito"
                : "Campaña enviada con exito",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-blue",
              },
              type: "success",
              icon: "success",
            }).then(function () {
              window.location.href = "/dashboard";
            });
          } else if (response.data.data === "3") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error creditos insuficientes",
              text:
                "Ocurrio un error enviando la lista " +
                self.nameContactList +
                " Revisar en Reportes las campañas enviadas y reenviela nuevamente",
              type: "error",
              icon: "error",
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange",
              },
              confirmButtonText: "OK",
            });
          } else if (response.data.data === "2") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error hora de envio invalido. Intente mas tarde",
              text:
                "Ocurrio un error enviando la lista " +
                self.nameContactList +
                " Revisar en Reportes las campañas enviadas y reenviela nuevamente",
              type: "error",
              icon: "error",
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange",
              },
              confirmButtonText: "OK",
            });
          } else {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error enviando mensaje ",
              text:
                "Ocurrio un error enviando la lista " +
                self.nameContactList +
                " Revisar en Reportes las campañas enviadas" +
                " y reenviela nuevamente",
              type: "error",
              icon: "error",
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-orange",
              },
              confirmButtonText: "OK",
            }).then(function () {
              window.location.href = "/envios/envios-programados";
            });
            console.log("No se encontró sendservice");
          }
        })
        .catch(function (error) {
          self.$refs.loader.hideLoader();
          Swal.fire({
            title: "Error enviando mensaje ",
            text:
              "Ocurrio un error enviando la lista " +
              self.nameContactList +
              " Revisar en Reportes las campañas enviadas" +
              " y reenviela nuevamente",
            type: "error",
            icon: "error",
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-celcom-orange",
            },
            confirmButtonText: "OK",
          }).then(function () {
            window.location.href = "/envios/envios-programados";
          });
          console.log("No se encontró sendservice", error);
        });
    },
    async envioServicesWhatsapp() {
      this.$refs.loader.showLoader();
      let self = this;
      self.totalCreditosUsar = self.sendingWhatsapp.campaignCost;
      if (!self.mostrarAlertEnvio) {
        var msn = !document.getElementById("ahora").checked
          ? "programar"
          : "enviar";
        Swal.fire({
          title: "¿Estás seguro de " + msn + " esta campaña?",
          text:
            "Se van a utilizar $" +
            self.totalCreditosUsar +
            " de tu cuenta",
          type: "info",
          icon: "info",
          showCancelButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-celcom-blue me-3",
            cancelButton: "btn btn-dark",
          },
          confirmButtonText: "Si, " + msn,
          cancelButtonText: "Cancelar",
          closeOnConfirm: false,
          closeOnCancel: false,
        }).then(function (isconfirm) {
          if (isconfirm.value) {
            self.mostrarAlertEnvio = true;
            self.envioServicesWhatsapp();
          }
        });
        self.$refs.loader.hideLoader();
        return false;
      }
      let user = await getUserRealTime();

      if (typeof this.$route.query.sending_sms !== "undefined") {
        await self.borrarSending(self.sendingWhatsapp);
      }

      if (!document.getElementById("ahora").checked) {
        var currentDate2 = new Date();
        currentDate2 = new Date(this.dateProgramgContact).getTime();
      }

      if (!self.checkEnvioProgramado) {
        return false;
      }

      var currentDate = new Date();
      currentDate = new Date(currentDate).getTime();
      moment.locale("es");
      self.validForm.dateSend = document.getElementById("ahora").checked
        ? moment(currentDate).format("LLL")
        : moment(currentDate2).format("LLL");

      self.sendingWhatsapp.calendar = document.getElementById("ahora").checked
        ? currentDate
        : currentDate2;

      let js = {
        customer_user: encodeURIComponent(JSON.stringify(user)),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        sending_sms: encodeURIComponent(JSON.stringify(this.sendingWhatsapp)),
        application_id: "1",
        sender: this.sender
          ? encodeURIComponent(JSON.stringify(this.sender))
          : encodeURIComponent(JSON.stringify({})),
        time_expired: horaAhora(),
        contacts: encodeURIComponent(
          JSON.stringify(
            Object.keys(this.contactLista).length !== 0 ? this.contactLista : []
          )
        ), // si es lista []
        filter: encodeURIComponent(
          JSON.stringify(
            Object.keys(this.contactLista).length !== 0 ? {} : this.data
          )
        ),
        short_url: encodeURIComponent(JSON.stringify({})),
        idSeguimiento: encodeURIComponent(JSON.stringify(this.idSeguimiento)),
      };
      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await sending_whatsapp_send(body)
        .then(function (response) {
          console.log(response);

          if (response.data.response === "200") {
            self.$refs.loader.hideLoader();

            Swal.fire({
              title: !document.getElementById("ahora").checked
                ? "Campaña programada con exito"
                : "Campaña enviada con exito",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-celcom-blue",
              },
              type: "success",
              icon: "success"
            }).then(function () {
              window.location.href = "/dashboard";
            });
          } else if (response.data.data === "3") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error creditos insuficientes",
              text:
                "Ocurrio un error enviando la lista " +
                self.nameContactList +
                " Revisar en Reportes las campañas enviadas y reenviela nuevamente",
              type: "error",
              icon: "error",
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              confirmButtonText: "OK",
            });
          } else if (response.data.data === "2") {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error hora de envio invalido. Intente mas tarde",
              text:
                "Ocurrio un error enviando la lista " +
                self.nameContactList +
                " Revisar en Reportes las campañas enviadas y reenviela nuevamente",
              type: "error",
              icon: "error",
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              confirmButtonText: "OK",
            });
          } else {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error enviando mensaje ",
              text:
                "Ocurrio un error enviando la lista " +
                self.nameContactList +
                " Revisar en Reportes las campañas enviadas" +
                " y reenviela nuevamente",
              type: "error",
              icon: "error",
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              confirmButtonText: "OK",
            }).then(function () {
              window.location.href = "/envios/envios-programados";
            });
            console.log("No se encontró sendservice");
          }
        })
        .catch(function (error) {
          self.$refs.loader.hideLoader();
          Swal.fire({
            title: "Error enviando mensaje ",
            text:
              "Ocurrio un error enviando la lista " +
              self.nameContactList +
              " Revisar en Reportes las campañas enviadas" +
              " y reenviela nuevamente",
            type: "error",
            icon: "error",
            showCancelButton: false,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
            confirmButtonText: "OK",
          }).then(function () {
            window.location.href = "/envios/envios-programados";
          });
          console.log("No se encontró sendservice", error);
        });
    },
    async envioServicesEmail() {
      this.$refs.loader.showLoader();
      let self = this;
      self.totalCreditosUsar = self.sendingEmail.campaignCost;
      if (!self.mostrarAlertEnvio) {
        var msn = !document.getElementById("ahoraEmail").checked
            ? "programar"
            : "enviar";
        Swal.fire({
          title: "¿Estás seguro de " + msn + " esta campaña?",
          text:
              "Se van a utilizar $" +
              self.totalCreditosUsar +
              " de tu cuenta",
          type: "info",
          icon: "info",
          showCancelButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-celcom-blue me-3",
            cancelButton: "btn btn-dark",
          },
          confirmButtonText: "Si, " + msn,
          cancelButtonText: "Cancelar",
          closeOnConfirm: false,
          closeOnCancel: false,
        }).then(function (isconfirm) {
          if (isconfirm.value) {
            self.mostrarAlertEnvio = true;
            self.envioServicesEmail();
          }
        });
        self.$refs.loader.hideLoader();
        return false;
      }
      let user = await getUserRealTime();

      if (typeof this.$route.query.sending_sms !== "undefined") {
        await self.borrarSending(self.sendingEmail);
      }

      if (!document.getElementById("ahoraEmail").checked) {
        var currentDate2 = new Date();
        currentDate2 = new Date(this.dateProgramgContact).getTime();
      }

      if (!self.checkEnvioProgramado) {
        return false;
      }

      var currentDate = new Date();
      currentDate = new Date(currentDate).getTime();
      moment.locale("es");
      self.validForm.dateSend = document.getElementById("ahoraEmail").checked
          ? moment(currentDate).format("LLL")
          : moment(currentDate2).format("LLL");

      self.sendingsms.calendar = document.getElementById("ahoraEmail").checked
          ? currentDate
          : currentDate2;

      let js = {
        customer_user: encodeURIComponent(JSON.stringify(user)),
        customer: encodeURIComponent(JSON.stringify(user.customer)),
        sending_sms: encodeURIComponent(JSON.stringify(self.sendingEmail)),
        application_id: "1",
        sender: this.sender
            ? encodeURIComponent(JSON.stringify(this.sender))
            : encodeURIComponent(JSON.stringify({})),
        time_expired: horaAhora(),
        contacts: encodeURIComponent(
            JSON.stringify(
                Object.keys(this.contactLista).length !== 0 ? this.contactLista : []
            )
        ), // si es lista []
        filter: encodeURIComponent(
            JSON.stringify(
                Object.keys(this.contactLista).length !== 0 ? {} : this.data
            )
        ),
        short_url: encodeURIComponent(JSON.stringify({})),
        idSeguimiento: encodeURIComponent(JSON.stringify(this.idSeguimiento)),
      };
      var body = new URLSearchParams();
      body.append("TOKEN", encryptAES(js));

      await sending_email_send(body)
          .then(function (response) {
            console.log(response);

            if (response.data.response === "200") {
              self.$refs.loader.hideLoader();

              Swal.fire({
                title: !document.getElementById("ahoraEmail").checked
                    ? "Campaña programada con exito"
                    : "Campaña enviada con exito",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-celcom-blue",
                },
                type: "success",
                icon: "success",
              }).then(function () {
                window.location.href = "/dashboard";
              });
            } else if (response.data.data === "3") {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Error creditos insuficientes",
                text:
                    "Ocurrio un error enviando la lista " +
                    self.nameContactList +
                    " Revisar en Reportes las campañas enviadas y reenviela nuevamente",
                type: "error",
                icon: "error",
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                confirmButtonText: "OK",
              });
            } else if (response.data.data === "2") {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Error hora de envio invalido. Intente mas tarde",
                text:
                    "Ocurrio un error enviando la lista " +
                    self.nameContactList +
                    " Revisar en Reportes las campañas enviadas y reenviela nuevamente",
                type: "error",
                icon: "error",
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                confirmButtonText: "OK",
              });
            } else {
              self.$refs.loader.hideLoader();
              Swal.fire({
                title: "Error enviando mensaje ",
                text:
                    "Ocurrio un error enviando la lista " +
                    self.nameContactList +
                    " Revisar en Reportes las campañas enviadas" +
                    " y reenviela nuevamente",
                type: "error",
                icon: "error",
                showCancelButton: false,
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                confirmButtonText: "OK",
              }).then(function () {
                window.location.href = "/envios/envios-programados";
              });
              console.log("No se encontró sendservice");
            }
          })
          .catch(function (error) {
            self.$refs.loader.hideLoader();
            Swal.fire({
              title: "Error enviando mensaje ",
              text:
                  "Ocurrio un error enviando la lista " +
                  self.nameContactList +
                  " Revisar en Reportes las campañas enviadas" +
                  " y reenviela nuevamente",
              type: "error",
              icon: "error",
              showCancelButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              confirmButtonText: "OK",
            }).then(function () {
              window.location.href = "/envios/envios-programados";
            });
            console.log("No se encontró sendservice", error);
          });
    },
  },
};
</script>
<style scoped>
/* Agrega estos estilos en tu archivo de estilos CSS */
.icon-options {
  display: flex;
  justify-content: center;
}

.icon-option {
  margin: 0 10px;
  cursor: pointer;
}

/* Puedes ajustar el tamaño de los iconos según tus necesidades */
.fa-lg {
  font-size: 50px;
}
.seleccionado {
  box-shadow: 0px 0px 0px 5px #15b68f;
}
</style>
